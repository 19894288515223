import React from 'react'
import {inject, observer} from 'mobx-react'

export default inject("rootstore", "langstore")(observer((props) => {

    return (
        <div className="sidebar_menu_bottom mobile-app-nav">
            <div onClick={() => {
                props.rootstore.uiSidebarToggle();
                props.rootstore.routeChange("/")
            }}>
                <i className="fa fa-home"/>
            </div>
            <div onClick={() => {
                props.rootstore.uiSidebarToggle();
                props.rootstore.routeChange("/profile")
            }}>
                <i className="fa fa-user-circle"/>
            </div>
            <div onClick={() => {
                props.rootstore.uiSidebarToggle();
                props.rootstore.routeChange("/comunicazioni");
            }}>
                <i className="fa fa-bell"/>
            </div>
            <div onClick={() => props.rootstore.uiSidebarToggle()}>
                <i className="fa fa-chevron-circle-left"/>
            </div>
        </div>
    )
}));

