import Loadable from '@loadable/component';
import Loading from '../system/views/loading.jsx';

const ModalPratiche = Loadable(() => import(/* webpackChunkName: "Modals1" */ "./modals/modalpratiche.jsx"));
const ModalNoteRiga = Loadable(() => import(/* webpackChunkName: "Modals2" */ "./modals/modalnoteriga.jsx"));
const ModalFatture = Loadable(() => import(/* webpackChunkName: "Modals3" */ "./modals/modalfatture.jsx"));
const ModalEmail = Loadable(() => import(/* webpackChunkName: "Modals4" */ "./modals/modalemail.jsx"));
const ModalConfirmReclamo = Loadable(() => import(/* webpackChunkName: "Modals5" */ "./modals/modalConfirmReclamo.jsx"));
const ModalSuccessReclamo = Loadable(() => import(/* webpackChunkName: "Modals6" */ "./modals/modalSuccessReclamo.jsx"));
const ModalPromo = Loadable(() => import(/* webpackChunkName: "Modals8" */ "./modals/modalPromo.jsx"));
const ModalLegenda = Loadable(() => import(/* webpackChunkName: "Modals9" */ "./modals/modalLegenda.jsx"));
const ModalFailReclamo = Loadable(() => import(/* webpackChunkName: "Modals10" */ "./modals/modalFailReclamo.jsx"));
const ModalTutorial = Loadable(() => import(/* webpackChunkName: "Modals11" */ "./modals/modalTutorial.jsx"));
const ModalLangSwitcher = Loadable(() => import(/* webpackChunkName: "Modals12" */ "./modals/modalLangSwitcher.jsx"));
const ModalSuccessAssistenza = Loadable(() => import(/* webpackChunkName: "Modals14" */ "./modals/modalSuccessAssistenza.jsx"));
const ModalPdf = Loadable(() => import(/* webpackChunkName: "Modals15" */ "./modals/modalpdf.jsx"));
const ModalConfirmGaranzia = Loadable(() => import(/* webpackChunkName: "Modals16" */ "./modals/modalConfirmGaranzia.jsx"));
const ModalConfermaOrdine = Loadable(() => import(/* webpackChunkName: "Modals17" */ "./modals/modalConfermaOrdine.jsx"));
const ModalConfermaRapidaOrdine = Loadable(() => import(/* webpackChunkName: "Modals17" */ "./modals/modalConfermaRapidaOrdine.jsx"));
const ModalRecapitiClienti = Loadable(() => import('./modals/modalRecapitiCliente.jsx'));
const ModalAggiornamentoSoftware = Loadable(() => import('./modals/modalAggiornamentoSoftware.jsx'));
const ModalAssetPim = Loadable(() => import('./modals/pim/modalAssetPim.jsx'));
const ModalCompanyInfo = Loadable(() => import("./modals/modalCompanyInfo.jsx"));
const modalAapOtpRejectNotes = Loadable(() => import("./modals/modalAapOtpRejectNotes.jsx"));
const ModalPdfViewer = Loadable(() => import(/* webpackChunkName: "Modals1" */ './modals/modalPdfViewer.jsx'));
const ModalLegendaSegnalazioni = Loadable(() => import(/* webpackChunkName: "Modals9" */ "./modals/modalLegendaSegnalazioni.jsx"));

let modals = {
    ModalPratiche,
    ModalNoteRiga,
    ModalFatture,
    ModalEmail,
    ModalConfirmReclamo,
    ModalSuccessReclamo,
    ModalPromo,
    ModalLegenda,
    ModalFailReclamo,
    ModalTutorial,
    ModalLangSwitcher,
    ModalSuccessAssistenza,
    ModalPdf,
    ModalConfirmGaranzia,
    ModalConfermaOrdine,
    ModalConfermaRapidaOrdine,
    ModalAssetPim,
    ModalCompanyInfo,
    ModalRecapitiClienti,
    ModalAggiornamentoSoftware,
    modalAapOtpRejectNotes,
    ModalPdfViewer,
    ModalLegendaSegnalazioni
}

export default modals;
