import * as agents from '../../src/application/actions/agents.js';
import * as cliente from '../../src/application/actions/cliente.js';
import * as invoices from '../../src/application/actions/invoices.js';
import * as ddt from '../../src/application/actions/ddt.js';
import * as orders from '../../src/application/actions/orders.js';
import * as reclami from '../../src/application/actions/reclami.js';
//import * as tweb from '../../src/application/actions/tweb.js';
//import * as email from '../../src/application/actions/email.js';
import * as profile from '../../src/application/actions/profile.js';
import * as pim from '../../src/application/actions/pim.js';
import * as promotions from '../../src/application/actions/promotions.js';
import * as statistics from '../../src/application/actions/statistics.js';
import * as internal from '../../src/application/actions/internal';
import * as dashboard from '../../src/application/actions/dashboard';
import * as assistenza from "../../src/application/actions/assistenza.js";
import * as garanzia from "../../src/application/actions/garanzia.js";

const actions = {
    ...agents,
    ...cliente,
    ...invoices,
    ...ddt,
    ...orders,
    ...reclami,
    //...tweb,
    //...email,
    ...profile,
    ...pim,
    ...promotions,
    ...statistics,
    ...internal,
    ...dashboard,
    ...assistenza,
    ...garanzia
}

export default actions;