let styles = {
	ROTTE:  'background: #555; color: #ffffff',
	API: 'background: #ddd; color: #333',
	ROOT: 'background: #911BFF; color: #ffffff',
	STORE: 'background: #009900; color: #ffffff',
	ERRORE: 'background: #ff1100; color: #ffffff',
	OK: 'background: #009900; color: #ffffff',
	WARNING: 'background: #ee5500; color: #ffffff',
	_DEFAULT: 'background: #555; color: #ffffff'
}



const log = function(content,_style,show){

	let style = _style || "_DEFAULT";
	let head = _style ? _style + " > " : "";
	let showErrors = this && this.config ? this.config.debugMessages.errors : show;
	let showOther = this && this.config ? this.config.debugMessages.other : show;

	if(showErrors === true && style === 'ERRORE'){
		console.log('%c' + ' ' + head + content + ' ', styles[style]);		
	}	

	else if(showOther === true && style != 'ERRORE'){
		console.log('%c' + ' ' + head + content + ' ', styles[style]);		
	}
}


export {log}