import React from 'react'
import {inject, observer} from 'mobx-react';

class Impersonification extends React.Component {
    constructor(props) {

        super(props);

        this.state = {
            user: null
        };

        this.logoutToImpersonatingUser = this.logoutToImpersonatingUser.bind(this);
        this.itemClick = this.itemClick.bind(this);
    }

    logoutToImpersonatingUser(c_utente) {
        this.props.rootstore.generateSSOOnBehalfOf(c_utente);
    }

    itemClick(e) {
        this.logoutToImpersonatingUser(this.props.rootstore.userInfo.impersonatingFrom)
    }

    render() {
        const {rootstore} = this.props;

        return (
            <React.Fragment>
                <div className="px-3 ">
                    <p className="t1" id="impersonification_option"><b>{rootstore.userInfo.impersonatingFrom}</b></p>
                    <button
                        type="button"
                        className="btn btn-danger btn-sm mb-0 btn-block font-weight-light"
                        onClick={() => this.logoutToImpersonatingUser(rootstore.userInfo.impersonatingFrom)}>
                        <i className="fas fa-sign-out-alt mr-2"/>
                        Logout agente
                    </button>
                </div>
            </React.Fragment>
        )
    }
}

export default inject("rootstore", "langstore")(observer(Impersonification));



      

