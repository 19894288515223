import * as orderEvents from '../../src/application/computed/orderEvents.js';
import langstore from '../system/i18n/translator';

const state = {
    dataOrderStates: [],

    dataEventiOrdine: null,
    dataEventiNotifiche: [],

    dataClients: [],
    dataClientsParams: [],
    dataClient: {},

    dataContacts: [],
    dataContactsParams: [],

    dataOrders: [],
    dataOrdersParams: {},
    dataOrdersClient: [],
    dataOrder: {},
    dataOrderVestizione: {},
    dataOrderReclami: [],

    dataOrderSets: [],

    dataOrderRelations: {},

    data3dviewer: {},
    dataAutorizzazioni: {},

    dataInvoices: [],
    dataInvoicesParams: {},
    dataInvoicesParams2: {},
    dataInvoicesDetail: {},

    dataPayments: [],
    dataPaymentsParams: {},

    dataAutorizzazioniReso: [],
    dataAutorizzazioniResoParams: {},

    dataUserDetails: {},

    dataDDT: [],
    dataDDTParams: {},

    dataSostituzioni: [],
    dataSostituzioniAttive: [],

    dataOrdiniCampioni: [],
    dataOrdiniNoteAssistenza: [],
    dataOrdiniExpo: [],

    dataPortafoglioordini: [],

    dataPortafoglioreclami: [],
    dataReclamo: {
        section: 1,
        pictures: [],
        rows: [],
        note: ""
    },

    // Pim
    dataSchedeMontaggioCategorie: [],
    dataSchedeMontaggioCategoria: [],
    dataSchedeMontaggioManuali: [],
    dataSchedaProdotto: [],
    dataPromoDetail: [],

    // info file delle pratiche
    dataDocumentActive: [],
    dataDocumentInfo: {},
    ordersetsParams: {},

    filterBlocked: false,

    verificaOtp: {
        type: "",
        otp: "",
        nomeRiferimento: "",
        nomeCliente: "",
        idOrdine: "",
        idReclamo: "",
        statoRisposta: "",
        statoRecord: "",
        chiaveOutput: "",
        userResponse: "",
        rejectNotes: "",
    },

    // Statistiche
    dataStatistics: {
        fatturato: [],
        classificazione: [],
        ordinato: [],
        portafoglio: [],
        dashboard: [],
        topnordini: [],
        acl_user: [],
        acl_user_selected: null
    },

    // Download file
    download_enable: false,
    xml_enable: false,

    warranty_enable: null,

    edit_warranty_email: null,

    infoStatiOrdineAttivo: [],

    suborder_visibility: false,

    idDownloading: false,

    comunicazioniOnSync: false,

    dataReclamoDettaglio: null,

    dataTutorial: [],

    warrantyLoading: false,

    total_size: 0,

    flag_reclamo: false,

    gruppi_portale: [],

    loading: false,

    tooltip: false,

    range_selected: [],

    dataAssistenza: {
        allegati: []
    },

    showRelazioni: false,

    statiOrdine: [
        {name: langstore.t('stato_ordine0', 'Da inserire'), value: 0},
        {name: langstore.t('stato_ordine10','Da confermare'), value: 10},
        {name: langstore.t('stato_ordine15','In conferma'), value: 15},
        {name: langstore.t('stato_ordine20','Confermato'), value: 20},
        {name: langstore.t('stato_ordine30','In produzione'), value: 30},
        {name: langstore.t('stato_ordine35','Consegna in pianificazione'), value: 35},
        {name: langstore.t('stato_ordine40','Consegna pianificata'), value: 40},
        {name: langstore.t('stato_ordine80','Consegnato'), value: 80},
        {name: langstore.t('stato_ordine50','Da fatturare'), value: 50},
        {name: langstore.t('stato_ordine90','Fatturato'), value: 90},
    ],

    statiOrdineAttivo: [
        {name: langstore.t('stato_ordine0', 'Da inserire'), value: 0},
        {name: langstore.t('stato_ordine10','Da confermare'), value: 10},
        {name: langstore.t('stato_ordine15','In conferma'), value: 15},
        {name: langstore.t('stato_ordine20','Confermato'), value: 20},
        {name: langstore.t('stato_ordine30','In produzione'), value: 30},
        {name: langstore.t('stato_ordine35','Consegna in pianificazione'), value: 35},
        {name: langstore.t('stato_ordine40','Consegna pianificata'), value: 40},
    ],

    tipiOrdine: [
        {name: langstore.t('order_type1','Completamento'), value: 'C'},
        {name: langstore.t('order_type2','Cucina'), value: '*'}
    ],

    statiGaranzia: [
        {name: langstore.t('','Non Disponibile'), value: -1, cssClass: 'badge badge-danger'},
        {name: langstore.t('','Disponibile'), value: 1, cssClass: 'badge badge-secondary'},
        {name: langstore.t('','Richiesta'), value: 0, cssClass: 'badge badge-warning'},
        {name: langstore.t('','In Attivazione'), value: 10, cssClass: 'badge badge-primary'},
        {name: langstore.t('','Attivata'), value: 20, cssClass: 'badge badge-success'},
    ],

    file: {
        name: '',
        url: '',
        data: null
    },

    dataWarranty: {
        exists: '',
        email: '',
        name: '',
        lastname: '',
        lang: '',
        update: false
    },

    paginationData: null,

    dataGridOptions: {
        borders: false,
        rows: true,
        columns: false,
        alternation: false
    },

    // Promozioni
    dataPromo: {
        data: [],
        pim: []
    },

    dataPim: {
        tutorial: [],
        guide: []
    },

    sezioniPortale: [
        {value:0,label: langstore.t('','Homepage')},
        {value:1,label: langstore.t('','Ordini')},
        {value:2,label: langstore.t('','Previsione Spedizioni')},
        {value:3,label: langstore.t('','Reclami')},
        {value:5,label: langstore.t('','Sostituzioni')},
        {value:6,label: langstore.t('','Amministrazione')},
        {value:7,label: langstore.t('','Expo')},
        {value:8,label: langstore.t('','Promozioni')},
        {value:9,label: langstore.t('','Virtual Tour')},
        {value:10,label: langstore.t('','Area Tecnica')},
        {value:11,label: langstore.t('','Software')},
        {value:12,label: langstore.t('','Download')},
        {value:13,label: langstore.t('','Arredo3')},
        {value:14,label: langstore.t('','Guide e Supporto')},
        {value:15,label: langstore.t('','Statistiche')},
        {value:16,label: langstore.t('','Profilo')},
        {value:17,label: langstore.t('','Garanzia')},
        {value:18,label: langstore.t('','Altro')}
    ],

    tipiDocumenti: [
        {name: "Conferma d'ordine", value: '15', key: 'doc_type_15'},
        {name: "Fattura di vendita", value: '45', key: 'doc_type_45'},
        {name: "Garanzia Standard", value: 'GARANZSTD', key: 'doc_type_GRS'},
        {name: "Assistenza Conto Garanzia", value: 'RIEPASSIST', key: 'doc_type_RIEPASSIST'},
        {name: "Avviso di assistenza al pagamento", value: 'AAPAG', key: 'AAPAG'},
    ],

    statiReclamo: [
        {value: 1, name: 'Inviata',     key: 'claim_status2', desKey: 'claim_status2_des', cssClass: 'badge badge-secondary', progressStatusValue: '25',  statusColor: 'rgba(134,136,136,0.71)'},
        {value: 2, name: 'Immessa',     key: 'claim_status3', desKey: 'claim_status3_des', cssClass: 'badge badge-info',      progressStatusValue: '50',  statusColor: 'rgba(59,59,89,0.82)'},
        {value: 3, name: 'In gestione', key: 'claim_status4', desKey: 'claim_status4_des', cssClass: 'badge badge-warning',   progressStatusValue: '75',  statusColor: 'rgba(168,150,93,0.79)'},
        {value: 4, name: 'Completata',  key: 'claim_status5', desKey: 'claim_status5_des', cssClass: 'badge badge-success',   progressStatusValue: '100', statusColor: 'rgba(112,169,128,0.8)'}
    ],

    workerUrl: "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js",
}

// Application state: computed values
const computedState = {
    ...orderEvents
}

export {state, computedState};
