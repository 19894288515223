import { autorun, extendObservable, toJS, runInAction  } from "mobx"
import { getParameterByName } from "../utilities/urls";

export default function(_this) {

  // routes autorun
  autorun( ()=> {

    var userToken = _this.userToken;
    var userTokenRoot = _this.userTokenRoot;
    var routeParams = _this.routeParamsCurrent;
    var routeCurrent = null;
    var routeTitle = "";

    const ssoToken = getParameterByName('ssoToken');
    const routePath = routeParams.routeObject && routeParams.routeObject.path;
    const ssoLogin = ssoToken && routePath == '/login';

    // SSO operations running. preventing standard checks
    if(_this.ssoLoginRunning){
      _this.log("Routesync bloccato per login SSO","WARNING");
      return;
    }
    // redirect to login is permission is insufficent
    if(!userToken && routeParams.unproctected != true && !ssoLogin){
      _this.log("Redirect a login perché non autenticato","WARNING");
      _this.routeChange("/login");
      return;
    }
    // redirect to user profile if trying to login while already logged
    if(userToken && routeParams.routeId == 'userlogin' && !ssoLogin){
      _this.log("Redirect da pagina login perché già autenticato","OK");
      _this.routeChange("/")
      return;
    }

    // redirect to index if trying to select service and service already selected
    if(userToken && _this.userService !== null && routeParams.routeId === 'userservice'){
      _this.log("Redirect da userservice, servizio già selezionato","OK");
      _this.routeChange("/")
      return;
    }
    // redirect to index if wrong URL format
    if(_this.config.app.hashsegment && window.location.href.search(_this.config.app.hashsegment) < 0){
      _this.log("Redirect per formato URL errato","WARNING");
      _this.routeChange("/")
      return;
    }
    // TODO
    // redirect to user selection if root token is active and user token not selected
    if(userToken && userTokenRoot && (userToken === userTokenRoot)){
      _this.routeChange("/projection");
      return;
    }

    // find route
    routeCurrent = routeParams.routeObject;


    // run actions
    if (routeCurrent && _this[routeCurrent.action] && (_this.appReady === true || routeParams.unproctected === true)) {
      _this.log("Azione: " + routeCurrent.action,"STORE");
      let routeAction = _this[routeCurrent.action];
      runInAction(()=>{
        routeAction.call(_this,routeCurrent,routeCurrent.options || null);
      });
    }
  })

}
