import React from 'react'
import ReactDOM from 'react-dom'
import {inject, observer} from 'mobx-react'

export default inject("rootstore")(observer((props) => {

  const isOnline = props.rootstore.isOnline;
  if(isOnline === true){
    return null;
  }

  return(
    <div className="tesy_offlinebanner">
      Attenzione, stai lavorando offline
    </div>
  )
  
}));