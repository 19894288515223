import React from 'react';
import ReactDOM from 'react-dom';
import {inject, observer} from 'mobx-react'

export default inject("rootstore", "langstore")(observer((props) => {

    return (
        <p className="version">{props.rootstore.feVersion} \ {props.rootstore.beVersion}</p>
    )
}));



      

