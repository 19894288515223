import {runInAction} from "mobx";

const getInfoUtente = async function () {
    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'profilo/getuserdetails',
        cacheAge: this.config.cacheAPI.initData,
        params: {},
        userToken: this.userToken
    });

    if (response) {
        runInAction(() => {
            this.dataUserDetails = response.data.details;
        });

        if (this.config && this.config.comunicazioni && this.config.comunicazioni === true) {
            runInAction(() => {
                this.comunicazioniOnSync = true;
            });

            this.dataLayer({
                url: this.config.paths.apiURL + 'comunicazioni/sync',
                cacheAge: this.config.cacheAPI.initData,
                params: {},
                userToken: this.userToken
            }).then(r => {
                runInAction(() => {
                    this.comunicazioniOnSync = false;
                    this.log("Newsletter sync completed");
                });
            });
        }

        if (this.config && this.config.popupAggiornamento && this.config.popupAggiornamento === true) {
            const softwareUpdate = await this.getLastVersionUpdate();

            if (softwareUpdate && response.data.details && response.data.details.hasOwnProperty('last_version_update') && response.data.details.last_version_update !== softwareUpdate.versione) {
                this.modalOpen('ModalAggiornamentoSoftware', {update: softwareUpdate})
            }
        }
    }

    return true;
}

const updateUserLastVersionRead = async function (params) {
    console.debug('Setting software update version for user');
    await this.dataLayer({
        url: this.config.paths.apiURL + 'profilo/updatelastversion',
        cacheAge: this.config.cacheAPI.initData,
        params: params,
        userToken: this.userToken
    });
}

export {
    getInfoUtente,
    updateUserLastVersionRead
}