import Loadable from '@loadable/component';
import Loading from '../system/views/loading.jsx';

const ModalMessage = Loadable(() => import(/* webpackChunkName: "Modals1s" */ "./modals/modalmessage.jsx"));
const ModalDefault = Loadable(() => import(/* webpackChunkName: "Modals2s" */ "./modals/modaldefault.jsx"));
const ModalAcl = Loadable(() => import(/* webpackChunkName: "Modals3s" */ "./modals/modalAcl.jsx"));
const ModalReset = Loadable(() => import(/* webpackChunkName: "Modals4s" */ "./modals/modalPasswordReset.jsx"));

let modals = {
    ModalMessage,
    ModalDefault,
    ModalAcl,
    ModalReset
}

export default modals;