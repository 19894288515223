import React from 'react'
import ReactDOM from 'react-dom'
import {inject, observer} from 'mobx-react'
import {toJS} from 'mobx'
import Route from 'route-parser';
import SidebarMenuMobile from '../../system/components/sidebarmenu_mobile.jsx';
import SidebarMenuDesktop from '../../system/components/sidebarmenu_desktop.jsx';

export default inject("rootstore","langstore")(observer((props) => {
  /*if(props.rootstore.viewportCurrent === 'mobile'){
    return (
        <SidebarMenuMobile />
    )
  }
  else{
    return(
      <SidebarMenuDesktop />
    )
  }*/

  return(
      <SidebarMenuDesktop />
  )

}));