import langstore from '../../system/i18n/translator.js';

const  currencyFormat = function(price,currency){
    if(!price){
      price = 0;
    }
    if(!currency)
      currency = "EUR";
    price = price.toFixed(2).replace(".",",").replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    return price +  " " + langstore.t("currency_"+currency,currency);
}

export {currencyFormat}