import {runInAction, toJS} from "mobx";
import moment from "moment";

const getStatistiche = async function (params, options)
{
    let _params = {
        anno: [moment().year()]
    };

    if (params.user){
        _params.c_utente = parseInt(params.user.value)
        this.dataStatistics.acl_user_selected = parseInt(params.user.value)
    }

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'statistiche',
        cacheAge: 0,
        params: _params,
        userToken: this.userToken
    });

    if (response) {
        runInAction(() => {
            this.dataStatistics.fatturato = response.data.fatturato;
            this.dataStatistics.classificazione = response.data.classificazione_ordini;
            this.dataStatistics.ordinato = response.data.ordinato;
            this.dataStatistics.acl_user = response.data.acl_user;
            if (response.data.acl_user_selected)
                this.dataStatistics.acl_user_selected = response.data.acl_user_selected
        });
    }
}

const getQlikFatturato = async function (params, options)
{
    let anni = params.map((item) => {
        return item.label
    })

    let _params = {
        anno: anni,
        cliente: this.dataStatistics.acl_user_selected || null
    };

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'statistiche/fatturato',
        cacheAge: 0,
        params: _params,
        userToken: this.userToken
    });

    if (response) {
        runInAction(() => {
            this.dataStatistics.fatturato = response.data;
        });
    }
}

const getQlikFatturatoClassificazione = async function (params, options)
{
    let _params = {
        anno: [params.label],
        cliente: this.dataStatistics.acl_user_selected || null
    };

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'statistiche/classificazione',
        cacheAge: 0,
        params: _params,
        userToken: this.userToken
    });

    if (response) {
        runInAction(() => {
            this.dataStatistics.classificazione = response.data;
        });
    }
}

const getQlikOrdinato = async function (params, options)
{
    let _params = {
        anno: [moment().year()]
    };

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'statistiche/ordinato',
        cacheAge: 0,
        params: _params,
        userToken: this.userToken
    });

    if (response) {
        runInAction(() => {
            this.dataStatistics.ordinato = response.data;
        });
    }
}

const getPortafoglioOrdini = async function (params, options)
{
    let _params = {
        anno: moment().year()
    };

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'statistiche/portafoglio',
        cacheAge: options && options.cacheAge ? options.cacheAge : this.config.cacheAPI.default,
        params: _params,
        userToken: this.userToken
    });

    if (response) {
        runInAction(() => {
            this.dataStatistics.portafoglio = response.data;
        });
    }
};

const getStatisticheDashboard = async function (params, options)
{
    let _params = {
        periodo: params ? params : 'month1'
    };

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'statistiche/dashboard',
        cacheAge: 0,
        params: _params,
        userToken: this.userToken
    });

    if (response) {
        runInAction(() => {
            this.dataStatistics.dashboard = response.data;
        });
    }
};

const getTopNOrdini = async function (params, options)
{
    let _params = {
        anno: moment().year()
    };

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'ordine/topn',
        cacheAge: 0,
        params: _params,
        userToken: this.userToken
    });

    if (response) {
        runInAction(() => {
            this.dataStatistics.topnordini = response.data;
        });
    }
}

const getAclUser = async function (params, options)
{
    let _params = {};

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'statistiche/alc/utenti',
        cacheAge: 0,
        params: _params,
        userToken: this.userToken
    });

    if (response) {
        runInAction(() => {
            this.dataStatistics.alc_user = response.data;
        });
    }
};

export {
    getStatistiche,
    getQlikFatturato,
    getQlikFatturatoClassificazione,
    getQlikOrdinato,
    getPortafoglioOrdini,
    getStatisticheDashboard,
    getTopNOrdini,
    getAclUser
}