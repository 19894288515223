import moment from 'moment';

function startOfWeek(date){
  var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
  var d = new Date(date.setDate(diff))
  d.setHours(24,0,0,0);
  return d;
}

function endOfWeek(date)
{
  var lastday = date.getDate() - (date.getDay() - 1) + 6;
  var d = new Date(date.setDate(lastday));
  d.setHours(24,0,0,0);
  return d;
}

function dateFormat(dateString,_format){
  let formatStatic = "DD/MM/YYYY";
  let out = "";
	let date = moment(dateString);
  if(date && dateString){
    out = date.utc().format(formatStatic);    
  }
	return out;
}

function timeFormat(dateString,_format){
  let formatStatic = "k:mm";
  let out = "";
  let date = moment(dateString);
  if(date && dateString){
    out = date.utc().format(formatStatic);    
  }
  return out;
}


export {startOfWeek,endOfWeek,dateFormat,timeFormat}