import {runInAction} from "mobx";

const loadClientiDisponibili = async function () {
    let url = this.config.paths.apiURL + "acl";

    let response = await this.dataLayer({
        url: url,
        method: "POST",
        cacheAge: 0,
        userToken: this.userToken
    });

    if (response && response.data) {
        this.acl = response.data;
    }
};

const aclAddUserModal = function (docs) {

    runInAction(() => {
        this.dataDocumentInfo = {};
        this.dataDocumentActive = docs;
        this.modalOpen("ModalAcl");
    });

};

const aclCreateUser = async function (params) {

    let url = this.config.paths.apiURL + "acl/user/create";

    let response = await this.dataLayer({
        url: url,
        method: "POST",
        cacheAge: 0,
        params: {
            ds_utente: params.aclUserDescrizione,
            nome: params.aclUserNome,
            cognome: params.aclUserCognome,
            c_nomi: params.aclUserClienti.length > 0 ? params.aclUserClienti.slice() : [],
            e_mail_utente: params.aclUserEmail,
            telefono_utente: params.aclUserTelefono,
            gruppi: params.aclUserGruppi,
            email: true
        },
        userToken: this.userToken
    });

    if (response && response.data) {
        this.getInfoUtente().then(() => {
            this.formData = {};
            this.modalCloseAll();
        });
    }
}

const aclDeleteUser = async function (c_utente) {

    let url = this.config.paths.apiURL + "acl/user/delete";

    let response = await this.dataLayer({
        url: url,
        method: "POST",
        cacheAge: 0,
        params: {
            c_utente: c_utente
        },
        userToken: this.userToken
    });

    return !!(response && response.status === 'OK');


}

export {
    loadClientiDisponibili,
    aclAddUserModal,
    aclCreateUser,
    aclDeleteUser
}