import moment from 'moment'
import {runInAction, toJS} from 'mobx';
import {Request, Response} from '../../system/utilities/network.js';
import menuIcons from 'Application/menuIcons'

const rangeToDates = function (_rangeId) {

    let rangeId = _rangeId || this.config.format.dateRangeDefault;
    let fr;
    let to = moment().format('YYYY-MM-DD');

    if (rangeId !== "custom") {

        switch (rangeId) {
            case 'year_current':
                fr = moment().startOf('year').format('YYYY-MM-DD');
                break;
            case 'month_current':
                fr = moment().startOf('month').format('YYYY-MM-DD');
                break;
            case 'week_current':
                fr = moment().startOf('week').format('YYYY-MM-DD');
                break;
            case 'day':
                fr = moment().subtract(1, 'days').format('YYYY-MM-DD');
                break;
            case 'week':
                fr = moment().subtract(7, 'days').format('YYYY-MM-DD');
                break;
            case 'month':
                fr = moment().subtract(30, 'days').format('YYYY-MM-DD');
                break;
            case 'month3':
                fr = moment().subtract(90, 'days').format('YYYY-MM-DD');
                break;
            case 'month4':
                fr = moment().subtract(120, 'days').format('YYYY-MM-DD');
                break;
            case 'year':
                fr = moment().subtract(365, 'days').format('YYYY-MM-DD');
                break;
            case 'year2':
                fr = moment().subtract(730, 'days').format('YYYY-MM-DD');
                break;
            case 'year5':
                fr = moment().subtract(1825, 'days').format('YYYY-MM-DD');
                break;
        }

    }

    return {
        fr: fr,
        to: to,
    }
}

const datesToRange = function (from, to) {

    let rangeId = "";

    if (from && to) {

        let rangeRelative = moment(from).diff(to, "days");
        let rangeStartYear = moment().startOf('year').diff(to, "days");
        let rangeStartMonth = moment().startOf('month').diff(to, "days");
        let rangeStartWeek = moment().startOf('week').diff(to, "days");

        if (rangeRelative === rangeStartYear) {
            rangeId = "year_current";
        } else if (rangeRelative === rangeStartMonth) {
            rangeId = "month_current";
        } else if (rangeRelative === rangeStartWeek) {
            rangeId = "week_current";
        } else {
            switch (rangeRelative) {
                case -1:
                    rangeId = 'day';
                    break;
                case -7:
                    rangeId = 'week';
                    break;
                case -30:
                    rangeId = 'month';
                    break;
                case -90:
                    rangeId = 'month3';
                    break;
                case -365:
                    rangeId = 'year';
                    break;
                case -730:
                    rangeId = 'year2';
                    break;
                case -1825:
                    rangeId = 'year5';
                    break;
                default:
                    rangeId = 'custom';
                    break;
            }
        }
    }

    return rangeId;
};

const gridExportExcel = function () {
    document.querySelector(".dx-datagrid-export-button").click();
}

const formDataUpdate = function (props) {

    let formData = toJS(this.formData);

    for (var prop in props) {
        formData[prop] = props[prop];
    }
    runInAction(() => {
        this.formData = formData;
    });
}

const updateLanguages = async function () {

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'languages',
        cacheAge: this.config.cacheAPI.initData,
        params: {},
        userToken: this.userToken
    });

    runInAction(() => {
        if (response) {
            this.languages = response.data.languageConfig.languages;
        }
    });

    return true;
}

const updateMenu = async function () {
    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'menu',
        cacheAge: this.config.cacheAPI.initData,
        params: {},
        userToken: this.userToken
    });

    if (response) {
        const menu = response.data

        const menuBuilder = (parentId, newId) => {
            var i = 0;
            const block = [];

            menu
                .sort((a, b) => {
                    if (a.n_ordinamento < b.n_ordinamento) {
                        return -1;
                    }
                    if (a.n_ordinamento > b.n_ordinamento) {
                        return 1;
                    }
                    return 0;
                })
                .forEach(m => {
                    let blockArray = [];

                    if (m.guid_item_padre !== parentId) {
                        return;
                    }

                    const children = menu.filter(mu => mu.guid_item_padre == m.guid);
                    const idMenu = newId ? `${newId}.${i + 1}` : `${i + 1}`;

                    const initialSlash = m.c_tipoazione != 'ssoredirect' && m.c_tipoazione != 'externalurl';

                    const mappedItem = {
                        idMenu,
                        path: `${initialSlash ? '/' : ''}${m.url}`,
                        actionType: m.c_tipoazione,
                        titleKey: `${m.c_chiave_traduzione}`,
                        titleString: `${m.ds_menu_item}`,
                        menu: `main`,
                        iconClass: menuIcons[m.c_menu_item],
                        params: m.parametri
                    };

                    this.enable_routes.push(`${initialSlash ? '/' : ''}${m.url}`);

                    if (newId) {
                        mappedItem.parent = newId;
                    }

                    blockArray.push(mappedItem);

                    if (children.length > 0) {
                        blockArray = [
                            ...blockArray,
                            ...menuBuilder(m.guid, idMenu)
                        ]
                    }

                    block.push(...blockArray);

                    i++;
                })
            return block
        }

        runInAction(() => {
            this.menus = menuBuilder(null);
        });
    }

    return true;
}

const updateAutorizzazioni = async function () {
    var request = {
        method: 'get',
        headers: {
            "Content-Type": "application/json",
            "x-access-token": this.userToken
        }
    };
    var url = this.config.paths.apiURL + "profilo/autorizzazioni";

    const response = await fetch(url, request).then(function (response) {
        return response.json();
    }).then(function (result) {
        return new Response(result);
    });

    runInAction(() => {
        if (response && response.data && response.data.autorizzazioni) {
            this.dataAutorizzazioni = response.data.autorizzazioni;
        }
    });

    return true
}

const updateEnabledEmails = async function () {
    this.enabledEmails = {};

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'email/enabled',
        cacheAge: 0,
        params: {},
        userToken: this.userToken
    });

    if (response && response.data && response.data.enabledMails) {
        this.enabledEmails = response.data.enabledMails;
    }

    return true;
}

const formatBytes = function (bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const getUltimoRunOk = async function () {
    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'common/ultimorunok',
        cacheAge: 0,
        params: {},
        userToken: this.userToken
    });

    runInAction(() => {
        if (response && response.data) {
            let ultimo_run_ok = moment(response.data[0].valore_datetime).utc().format('DD/MM/YYYY - HH:mm');
            this.last_update = (ultimo_run_ok).split('-')[1];
            this.next_update = (parseInt(this.last_update)+4).toString() + ':00';
        }
    });
}

const getBeVersion = async function () {
    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'system/version',
        cacheAge: 0,
        params: {},
        userToken: this.userToken
    });

    runInAction(() => {
        if (response && response.data) {
            this.beVersion = response.data;
        }
    });
}

const fetchDataTable = function (props, cacheID) {
    switch (props.data) {
        case 'archivio_ordini':
            props.rootstore.portafoglioordini(props.rootstore.cacheParams[cacheID], {cacheAge: 1})
            break;
        case 'ordini_attivi':
            props.rootstore.orders(props.rootstore.cacheParams[cacheID], {cacheAge: 1})
            break;
        case 'ordini_campioni':
            props.rootstore.ordiniCampioni(props.rootstore.cacheParams[cacheID], {cacheAge: 1})
            break;
        case 'note_assistenza':
            props.rootstore.ordiniNoteAssistenza(props.rootstore.cacheParams[cacheID], {cacheAge: 1})
            break;
        case "sostituzioni":
            props.rootstore.sostituzioni(props.rootstore.cacheParams[cacheID], {cacheAge: 1})
            break;
        case "sostituzioni_attive":
            props.rootstore.sostituzioniAttive(props.rootstore.cacheParams[cacheID], {cacheAge: 1})
            break;
        case 'previsioni':
            props.rootstore.portafoglioordini(props.rootstore.cacheParams[cacheID], {cacheAge: 1})
            break;
        case 'ordini_expo':
            props.rootstore.orderExpo(props.rootstore.cacheParams[cacheID], {cacheAge: 1})
            break;
        case 'reclami':
            props.rootstore.portafoglioreclami(props.rootstore.cacheParams[cacheID], {cacheAge: 1})
            break;
        case 'fatture':
            props.rootstore.invoiceList(props.rootstore.cacheParams[cacheID], {cacheAge: 1})
            break;
        case 'note_accredito':
            props.rootstore.paymentList(props.rootstore.cacheParams[cacheID], {cacheAge: 1})
            break;
        case 'autorizzazioni_reso':
            props.rootstore.autorizzazioniResoList(props.rootstore.cacheParams[cacheID], {cacheAge: 1})
            break;
        case 'ddt':
            props.rootstore.ddtList(props.rootstore.cacheParams[cacheID], {cacheAge: 1})
            break;
    }
}

/*
const updateCapigruppo = async function () {

    // API call
    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'cliente/capigruppo',
        cacheAge: this.config.cacheAPI.initData,
        params: {},
        userToken: this.userToken
    });

    if (response && response.data && response.data.capigruppo) {

        let out = response.data.capigruppo.map((n) => {
            return {
                id: n.c_cliente_gruppo,
                label: n.ragione_sociale_1
            }
        });

        this.infoCapigruppo = out
    }
    return true;
}
*/

/*
const updateNumeratori = async function () {

    // this.infoNumeratori = [{id: "OCL", label: "Nome 1"},{id: "OCL2", label: "Nome 2"},{id: "OCL3", label: "Nome 3"}];

    // API call
    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'common/numeratorivendita',
        cacheAge: this.config.cacheAPI.initData,
        params: {},
        userToken: this.userToken
    });

    if (response && response.data && response.data.numeratori) {

        let out = response.data.numeratori.map((n) => {
            return {
                id: n.c_numeratore,
                label: n.ds_numeratore
            }
        });

        this.infoNumeratori = out
    }

    return true;
}
*/

/*
const updateStatiOrdine = async function () {

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'common/statiordinevenditaportale',
        cacheAge: this.config.cacheAPI.initData,
        params: {},
        userToken: this.userToken
    });

    if (response && response.data && response.data.stati) {

        let out = response.data.stati.map((n) => {
            return {
                id: n.fg_stato_ordine_portale,
                label: n.ds_fg_stato_ordine_portale
            }
        });

        this.infoStatiOrdine = out
    }

    return true;
}
*/

/*
const updateStatiOrdineAttivo = async function () {
    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'common/statiordineattivo',
        cacheAge: this.config.cacheAPI.initData,
        params: {},
        userToken: this.userToken
    });

    if (response && response.data && response.data.stati) {

        let out = response.data.stati.map((n) => {
            return {
                id: n.n_stato_portale,
                label: n.ds_stato
            }
        });

        this.infoStatiOrdineAttivo = out
    }

    return true;
};
*/

/*
const updateEventiOrdini = async function () {

    // this.dataEventiOrdine = null;
    // let requestBody = new Request({});
    // delete requestBody.data;

    // let headers = { "Content-Type": "application/json" };
    // if(this.userToken !== null){
    //     headers["x-access-token"] = this.userToken;
    // }
    // let request = { method: 'post', headers: headers };
    // request.body = JSON.stringify(requestBody);
    // let _response = await fetch(this.config.paths.apiURL + 'ordine/eventiordine', request);
    // let response = await _response.json();
    // let then = + new Date();
    // let result = new Response(response);


    // let mock = {
    //             "dt_richiesta": "2019-11-26T17:07:16.807Z",
    //             "n_evento": 330659,
    //             "n_anno": 2019,
    //             "c_numeratore": "OCL",
    //             "n_ordine": 10557,
    //             "c_stato": 10,
    //             "ds_stato": "DA ELABORARE",
    //             "dt_elaborazione": null,
    //             "log_errore": " ",
    //             "DS_n_ordine": "2019-OCL-10557"
    //         };

    // if(result && result.data){
    //   // this.dataEventiOrdine = result.data;
    //   this.dataEventiOrdine = [mock];
    //   this.orderSyncNotifiche();
    // }

    return true;

}
*/

/*
const updateStatiReclamo = async function () {

    // API call
    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'common/statireclamo',
        cacheAge: this.config.cacheAPI.initData,
        params: {},
        userToken: this.userToken
    });

    if (response && response.data && response.data.capigruppo) {

        // let out = response.data.capigruppo.map((n)=>{
        //   return {
        //     id: n.c_cliente_gruppo,
        //     label: n.ragione_sociale_1
        //   }
        // });

        console.log(response.data);

        // this.infoStatiReclamo = out
    }
    return true;
}
*/
export {
    formDataUpdate,
    gridExportExcel,
    rangeToDates,
    datesToRange,
    updateLanguages,
    updateMenu,
    updateAutorizzazioni,
    updateEnabledEmails,
    getUltimoRunOk,
    formatBytes,
    getBeVersion,
    fetchDataTable,
    //updateCapigruppo,
    //updateNumeratori,
    //updateStatiOrdine,
    //updateStatiOrdineAttivo,
    //updateEventiOrdini,
    //updateStatiReclamo
}