import langstore from '../system/i18n/translator.js';

const routes = [
	/** ANAGRAFICA */
	{
		id: 'clienti',
		path: "/clienti(/page/:pageNumber)",
		content: "ClientiArchivio",
		action: "clientlist",
		navigation: "NavigationTitle",
		sidebar: 'SidebarMenu',
		title: '',
		titleKey: "section_clienti",
		titleString: "Punti vendita",
	},
	{
		id: 'clienti-dettaglio',
		path: "/clienti/dettaglio/:idClient",
		content: 'ClientiDettaglio',
		action: "clientdetail",
		navigation: "NavigationTitle",
		sidebar: 'SidebarMenu',
		titleString: "Dettaglio Punto Vendita",
		titleKey: "section_clienti_dettaglio"
	},

	/** ORDINI */
	{
		id: 'ordini',
		path: "/ordini",
		content: "OrdiniArchivio",
		action: 'portafoglioordini',
		navigation: "NavigationTitle",
		sidebar: 'SidebarMenu',
		titleString: "Ordini",
		titleKey: "section_ordini_archivio",
	},
	{
		id: 'ordini-attivi',
		path: "/ordini/attivi",
		content: 'OrdiniAttivi',
		action: 'orders',
		navigation: "NavigationTitle",
		sidebar: 'SidebarMenu',
		titleString: "",
		titleKey: "",
	},
	{
		id: 'ordini-campioni',
		path: "/ordini/campione",
		content: "OrdiniCampioni",
		action: 'ordiniCampioni',
		navigation: "NavigationTitle",
		sidebar: 'SidebarMenu',
		titleString: "",
		titleKey: "",
	},
	{
		id: 'noteassistenza',
		path: "/ordini/noteassistenza",
		content: "OrdiniNoteAssistenza",
		action: 'ordiniNoteAssistenza',
		navigation: "NavigationTitle",
		sidebar: 'SidebarMenu',
		titleString: "",
		titleKey: "",
	},
	{
		id: 'ordini-detail',
		path: '/ordini/dettaglio/:orderYear/:orderNumber/:orderId',
		content: "OrdiniDettaglio",
		action: 'orderdetail',
		navigation: "NavigationTitle",
		sidebar: 'SidebarMenu',
		titleString: "",
		titleKey: "",
	},

	/** POST VENDITA */
	{
		id: "segnalazioni",
		path: "/post-vendita/segnalazioni",
		content: "ReclamiArchivio",
		action: "portafoglioreclami",
		navigation: "NavigationTitle",
		sidebar: "SidebarMenu",
		titleString: "",
		titleKey: "",
	},
	{
		id: "sostituzioni",
		path: "/post-vendita/sostituzioni",
		content: "SostituzioniArchivio",
		action: "sostituzioni",
		navigation: "NavigationTitle",
		sidebar: "SidebarMenu",
		titleString: "",
		titleKey: "",
	},
	{
		id: "autorizzazionireso",
		path: "/post-vendita/autorizzazioni-reso",
		content: "AutorizzazioniReso",
		action: "autorizzazioniResoList",
		navigation: "NavigationTitle",
		sidebar: "SidebarMenu",
		titleString: "",
		titleKey: "",
	},
	{
		id: "accredito-senza-reso",
		path: "/post-vendita/accredito-senza-reso",
		content: "FattureNoteAccredito",
		action: "paymentList",
		navigation: "NavigationTitle",
		sidebar: "SidebarMenu",
		titleString: "",
		titleKey: "",
	},
	{
		id: "reclamo-detail",
		path: "/post-vendita/segnalazioni/dettaglio/:reclamoId",
		content: "ReclamiDettaglio",
		action: "dettaglioReclamo",
		navigation: "NavigationTitle",
		sidebar: "SidebarMenu",
		titleString: "",
		titleKey: "",
	},
	{
		id: "nuovoreclamo",
		path: "/post-vendita/nuovasegnalazione/:orderYear/:orderNumber/:orderId",
		navigation: "NavigationTitle",
		content: "ReclamiNuovoReclamo",
		action: "orderdetail",
		sidebar: "SidebarMenu",
		titleString: "",
		titleKey: "",
	},

	/** SOSTITUZIONI (old)*/
	/*{
		id: 'sostituzioni',
		path: "/sostituzioni",
		content: "SostituzioniArchivio",
		action: 'sostituzioni',
		navigation: "NavigationTitle",
		sidebar: 'SidebarMenu',
		titleString: "",
		titleKey: "",
	},
	{
		id: 'sostituzioni-attive',
		path: "/sostituzioni/attive",
		content: "SostituzioniAttive",
		action: 'sostituzioniAttive',
		navigation: "NavigationTitle",
		sidebar: 'SidebarMenu',
		titleString: "",
		titleKey: "",
	},*/

	/** RECLAMI (old)*/
	/*{
		id: 'reclami',
		path: "/reclami",
		content: "ReclamiArchivio",
		action: 'portafoglioreclami',
		navigation: "NavigationTitle",
		sidebar: 'SidebarMenu',
		titleString: "",
		titleKey: "",
	},
	{
		id: 'reclami-detail',
		path: '/reclami/dettaglio/:reclamoId',
		content: "ReclamiDettaglio",
		action: 'dettaglioReclamo',
		navigation: "NavigationTitle",
		sidebar: 'SidebarMenu',
		titleString: "",
		titleKey: "",
	},
	{
		id: 'nuovoreclamo',
		path: "/reclami/nuovoreclamo/:orderYear/:orderNumber/:orderId",
		navigation: "NavigationTitle",
		content: "ReclamiNuovoReclamo",
		action: 'orderdetail',
		sidebar: 'SidebarMenu',
		titleString: "",
		titleKey: "",
	},*/

	/** AMMINISTRAZIONE */
	{
		id: 'fatture',
		path: '/fatture',
		content: "FattureArchivio",
		action: 'invoiceList',
		navigation: "NavigationTitle",
		sidebar: 'SidebarMenu',
		titleString: "",
		titleKey: "",
	},
	{
		id: 'ddt',
		path: '/ddt',
		content: "DdtArchivio",
		action: 'ddtList',
		navigation: "NavigationTitle",
		sidebar: 'SidebarMenu',
		title: ""
	},
	/*{
		id: 'noteaccredito',
		path: '/fatture/noteaccredito',
		content: 'FattureNoteAccredito',
		action: 'paymentList',
		navigation: 'NavigationTitle',
		sidebar: 'SidebarMenu',
		titleString: "",
		titleKey: "",
	},
	{
		id: 'autorizzazionireso',
		path: '/fatture/autorizzazioni-reso',
		content: 'AutorizzazioniReso',
		action: 'autorizzazioniResoList',
		navigation: 'NavigationTitle',
		sidebar: 'SidebarMenu',
		titleString: "",
		titleKey: "",
	},*/

	/** PREVISIONE SPEDIZIONI */
	{
		id: 'shipping-forecast',
		path: '/previsionespedizioni',
		content: 'PrevisioneSpedizioniArchivio',
		action: 'previsioneSpedizioni',
		navigation: 'NavigationTitle',
		sidebar: 'SidebarMenu',
		titleString: "",
		titleKey: "",
	},

	/** PROMOZIONI */
	{
		id: 'promozioni',
		path: '/promozioni',
		content: 'PromozioniArchivio',
		action: 'promotionsList',
		navigation: 'NavigationTitle',
		sidebar: 'SidebarMenu',
		titleString: "",
		titleKey: "",
	},

	/** VIRTUAL TOUR */
	{
		id: 'virtualtour_scorze',
		path: "/virtual-tour-scorze",
		content: 'VirtualTourScorze',
		navigation: "NavigationTitle",
		sidebar: 'SidebarMenu',
		action: '',
		titleString: "",
		titleKey: "",
	},
	{
		id: 'virtualtour_milano',
		path: "/virtual-tour-milano",
		content: 'VirtualTourMilano',
		navigation: "NavigationTitle",
		sidebar: 'SidebarMenu',
		action: '',
		titleString: "",
		titleKey: "",
	},

	/** AREA TECNICA */
	{
		id: 'schede-montaggio',
		path: "/schede-montaggio",
		content: 'SchedeMontaggioHome',
		navigation: "NavigationTitle",
		sidebar: 'SidebarMenu',
		action: 'schedemontaggio',
		titleString: "",
		titleKey: "",
		title: "",
	},
	{
		id: 'schede-montaggio-category',
		path: "/schede-montaggio/category/:categoryID",
		content: 'SchedeMontaggioCategory',
		navigation: "NavigationTitle",
		sidebar: 'SidebarMenu',
		action: 'schedemontaggiocategory',
		titleString: "",
		titleKey: "",
	},
	{
		id: 'scheda-prodotto',
		path: "/scheda-prodotto",
		content: 'SchedaProdotto',
		navigation: "NavigationTitle",
		sidebar: 'SidebarMenu',
		action: 'schedaprodotto',
		titleString: "",
		titleKey: "",
	},

	/** AREA SOFTWARE */
	{
		id: 'requisiti-software',
		path: "/requisiti-software",
		content: 'RequistiSoftware',
		navigation: "NavigationTitle",
		sidebar: 'SidebarMenu',
		titleString: "",
		titleKey: "",
	},
	{
		id: 'metron-installer',
		path: "/metron-installer",
		content: 'MetronInstaller',
		navigation: "NavigationTitle",
		sidebar: 'SidebarMenu',
		titleString: "",
		titleKey: "",
	},

	/** ARREDO3 */
	{
		id: 'arredo3-recapiti',
		path: "/recapitiservizi",
		content: 'Arredo3Recapiti',
		navigation: "NavigationTitle",
		sidebar: 'SidebarMenu',
		titleString: "",
		titleKey: "",
	},

	/** TWEB */
	{
		id: 'tweb_testata',
		path: '/tweb/testata',
		action: 'twebredirect',
		navigation: "NavigationTitle",
		sidebar: 'SidebarMenu',
		content: "Loading",
		title: langstore.t("section_redirect", 'Redirect')
	}, 
	{
		id: 'tweb_ordinisospesi',
		path: '/tweb/ordinisospesi',
		action: 'twebredirect',
		navigation: "NavigationTitle",
		sidebar: 'SidebarMenu',
		content: "Loading",
		title: langstore.t("section_redirect", 'Redirect')
	}, 
	{
		id: 'tweb_ordiniconfermati',
		path: '/tweb/ordiniconfermati',
		action: 'twebredirect',
		navigation: "NavigationTitle",
		sidebar: 'SidebarMenu',
		content: "Loading",
		title: langstore.t("section_redirect", 'Redirect')
	},

	/** 3D VIEW */
	{
		id: 'gtlfviewer',
		path: "/3dview/:orderYear/:orderNumber/:orderId",
		content: "GtlfViewer",
		//navigation: "NavigationTitle",
		//sidebar: 'SidebarMenu',
		action: 'view3dModel',
		//title: langstore.t("section_3dview",'Visualizza 3D')
	},

	/** EXPO */
	{
		id: 'expo',
		path: '/expo',
		content: 'Expo',
		action: 'orderExpo',
		navigation: "NavigationTitle",
		sidebar: 'SidebarMenu',
		titleString: "",
		titleKey: "",
	},

	/** TUTORIAL */
	{
		id: 'tutorial',
		path: '/tutorial',
		content: 'Tutorial',
		action: 'tutorial',
		navigation: "NavigationTitle",
		sidebar: 'SidebarMenu',
		titleString: '',
		titleKey: 'section_guide',
	},

	/** STATISTICHE */
	{
		id: 'statistiche',
		path: '/statistiche',
		content: 'Statistiche',
		action: 'getStatistiche',
		navigation: "NavigationTitle",
		sidebar: 'SidebarMenu',
		titleString: '',
		titleKey: '',
	},

	/** ASSISTENZA */
	{
		id: 'assistenza',
		path: '/assistenza',
		content: 'Assistenza',
		action: '',
		navigation: "NavigationTitle",
		sidebar: 'SidebarMenu',
		titleString: '',
		titleKey: '',
	},

	/** ACADEMY */
	{
		id: "academy",
		path: "/academy",
		content: "Academy",
		action: "",
		navigation: "NavigationTitle",
		sidebar: "SidebarMenu",
		titleString: "",
		titleKey: "",
	},

	/** DOWNLOAD */
	{
		id: 'area_download2',
		path: '/download',
		content: 'AreaDownload',
		action: '',
		navigation: "NavigationTitle",
		sidebar: 'SidebarMenu',
		titleString: '',
		titleKey: '',
	},

	/** CONFERMA ORDINE OTP */
	{
		id: "otp-co",
		path: "/ordini/da-confermare/verifica/:language/:token",
		content: "ConfermaOtpRichiestaConfermaOrdine",
		action: "otpCheckRetrieveDetails",
		unproctected: true,
		navigation: null,
	},

	/** CONFERMA ORDINE OTP RETROCOMPATIBILE (senza passaggio language) */
	{
		id: "otp-co-legacy",
		path: "/ordini/da-confermare/verifica/:token",
		content: "ConfermaOtpRichiestaConfermaOrdine",
		action: "otpCheckRetrieveDetails",
		unproctected: true,
		navigation: null,
	},

	/** AAP (Autorizzazione Al Pagamento) OTP */
	{
		id: "opt-aap",
		path: "/aap/da-confermare/verifica/:language/:token",
		content: "ConfermaOtpAap",
		action: "otpCheckRetrieveDetails",
		unproctected: true,
		navigation: null,
	}
];

export default routes;
