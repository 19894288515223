import {runInAction} from 'mobx';
import {Request, Response} from '../../system/utilities/network.js';
import {toJS} from 'mobx';
import langstore from '../../system/i18n/translator.js';
import {is_touch_device} from '../../system/utilities/devices.js'
import {ApolloClient, gql, InMemoryCache} from "@apollo/client";
import Compressor from "compressorjs";
import {validateExtensionImage} from "System/utilities/files";

/*
var saveData = (function () {
    function arrayBufferToBase64(buffer) {
        let binary = '';
        let bytes = new Uint8Array(buffer);
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    var a = document.getElementById("ts_download_link");

    return function (blob, filename) {

        let reader = new FileReader();
        reader.onload = (e) => {
            let url2 = reader.result;
            var url = arrayBufferToBase64(url2);
            url = "data:application/octet-stream;base64," + url;
            a.href = url;
            a.download = filename;
            setTimeout(() => {
                a.click();
            }, 100);
        }
        reader.readAsArrayBuffer(blob);

    };

}());
*/

/*
const showDataTouch = async function (requestData) {

    function arrayBufferToBase64(buffer) {
        let binary = '';
        let bytes = new Uint8Array(buffer);
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    let url = this.config.paths.apiURL + "pratiche/downloadfile";
    let headers = {"Content-Type": "application/json", "x-access-token": this.userToken};
    let request = {method: 'post', headers: headers};
    let requestBody = new Request({data: requestData});
    request.body = JSON.stringify(requestBody);

    let _response = await fetch(url, request);
    let _headers = _response.headers;
    let blob = await _response.blob();
    let filename = _headers.get("Content-Disposition");
    let contentType = _headers.get("Content-Type");

    filename = filename ? filename.split("filename=").pop() : "temp";

    if (blob.size < 200 || filename == "temp") {
        this.notificationAdd(langstore.t("file_invalid", "File non trovato"), "fileinvalid2", 3000, "error");
    } else {

        var a = document.getElementById("ts_download_link");
        let reader = new FileReader();

        reader.onload = (e) => {
            let url2 = reader.result;
            var url = arrayBufferToBase64(url2);
            url = "data:" + contentType + ";base64," + url;
            a.href = url;
            a.download = filename;
            setTimeout(() => {
                a.click();
            }, 100);
        }
        reader.readAsArrayBuffer(blob);

    }

};
*/

/*
var showData = (function () {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    return function (blob) {
        var url = window.URL.createObjectURL(blob);
        a.target = "_blank";
        a.href = url;
        setTimeout(() => {
            a.click();
        }, 100);

        setTimeout(() => {
            URL.revokeObjectURL(url);
        }, 10000);
    };
}());
*/

/*
const filePreview = async function () {

    this.modalCloseAll();
    this.loadingAdd("filePreview2222");

    let url2 = this.config.paths.apiURL + "pratiche/downloadfile";
    let headers = {"Content-Type": "application/json", "x-access-token": this.userToken};
    let request = {method: 'post', headers: headers};
    let routeParams = this.routeParamsCurrent.filedata || {};
    let requestData = JSON.parse(atob(routeParams));
    let requestBody = new Request({data: requestData});

    request.body = JSON.stringify(requestBody);
    let _response = await fetch(url2, request);
    let blob = await _response.blob();
    let _headers = _response.headers;
    let type = "";
    let _title = routeParams;

    _headers.forEach(function (val, key) {
        if (key === "content-type") {
            type = val;
        }
        if (key === "content-disposition") {
            _title = val;
            _title = val.split("filename=");
            _title = _title.length >= 1 ? _title[1] : _title[0];
        }
    });

    this.loadingRemove("filePreview");

    if (blob.size < 200) {
        this.notificationAdd(langstore.t("file_invalid", "File non trovato"), "fileinvalid2", 3000, "error");
    } else {
        // this.filePreview(item,_title,file,blob,_type);

        function arrayBufferToBase64(buffer) {
            let binary = '';
            let bytes = new Uint8Array(buffer);
            let len = bytes.byteLength;
            for (let i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            return window.btoa(binary);
        }

        // objecturl test
        let file = requestData;
        let item = requestData;
        let title = _title;
        var url = window.URL.createObjectURL(blob);
        this.previewData = {title, url, item, file, type};
    }
}
*/

/*
const downloadAll = async function (items, key) {

    let url = this.config.paths.apiURL + "pratiche/downloadfile";
    let headers = {"Content-Type": "application/json", "x-access-token": this.userToken};
    let request = {method: 'post', headers: headers};
    let docKey = key ? key : "DS_order_docs";

    let files = [];
    for (var i = items.length - 1; i >= 0; i--) {
        let item = items[i];
        let docs = item[docKey];
        if (docs) {
            for (var ii = docs.length - 1; ii >= 0; ii--) {
                let doc = docs[ii];
                files.push({
                    "c_pratica": doc.c_pratica,
                    "c_pratica_guid": doc.c_pratica_guid
                });
            }
        }
    }

    if (files.length == 0) {
        this.notificationAdd(langstore.t("download_nofiles", "La selezione non contiene pratiche"), "fileinvalid0");
    } else {

        let requestBody = new Request({data: files});
        request.body = JSON.stringify(requestBody);

        this.notificationAdd(langstore.t("download_start", "Download in preparazione..."), "fileinvalid1");
        let _response = await fetch(url, request);

        let headers = _response.headers;
        let filename = headers.get("Content-Disposition");
        let blob = await _response.blob();

        filename = filename ? filename.split("filename=").pop() : "temp";

        if (blob.size < 200 || filename == "temp") {
            this.notificationAdd(langstore.t("file_invalid", "File non trovato"), "fileinvalid2", 3000, "error");
        } else {
            this.notificationAdd(langstore.t("download_ready", "Download pronto"), "fileinvalid3");
            saveData(blob, filename);
        }

    }

}
*/

/*
const showSingle = async function (item, title, file) {
    let requestData = {
        c_pratica: item.c_pratica,
        c_pratica_guid: item.c_pratica_guid
    }

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'pratiche/downloadfile',
        params: requestData,
        userToken: this.userToken
    });

    let pdfBlob = dataURItoBlob('data:application/pdf;base64,' + response.data);

    const url = window.URL.createObjectURL(pdfBlob);

    window.open(url);
}
*/

/*
const downloadClear = function () {
    this.downloads = [];
}
*/

/*
const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1])
    const mimeString = dataURI
        .split(',')[0]
        .split(':')[1]
        .split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
    }

    // write the ArrayBuffer to a blob, and you're done
    return new Blob([ab], {type: mimeString})
}
*/

/*
const downloadFileFromUrl = async function (file) {
    let file_name = file.node.file.filename;
    file = toJS(file.node.file)
    //console.log(file);
    let res = URL.createObjectURL(new Blob([file.filesize], { type: 'application/pdf' }));
    console.log(res)

    runInAction(() => {
        this.file = res;
    });
}
*/

/*
const downloadSingle = async function (item, title, file) {

    var location = this.config.paths.apiURL + "pratiche/downloadfile" + "?";

    let requestData = {
        c_pratica: item.c_pratica,
        c_pratica_guid: item.c_pratica_guid
    }

    if (file) {
        requestData.c_versione = file.c_versione;
        requestData.c_riga = file.c_riga;
    }

    Object.keys(requestData).map(k => {
        location += "&" + k + "=" + requestData[k];
    });

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'pratiche/downloadfile',
        params: requestData,
        userToken: this.userToken
    });

    let pdf = response.data;

    const linkSource = 'data:application/pdf;base64,' + pdf;

    var pdfBlob = dataURItoBlob(linkSource);

    const url = window.URL.createObjectURL(pdfBlob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', item.c_pratica + '.pdf');
    document.body.appendChild(link);
    link.click();
    link.remove();

    return;
}
*/

const showFile = async function (file){
    this.file.url = '';

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'pratiche/downloadfile',
        params: {
            c_pratica: file.c_pratica,
            c_pratica_guid: file.c_pratica_guid
        },
        userToken: this.userToken
    });

    runInAction(() => {
        if (response) {
            let bytes = atob(response.data);
            let length = bytes.length;
            let out = new Uint8Array(length);

            while (length--) {
                out[length] = bytes.charCodeAt(length);
            }

            this.file.url = URL.createObjectURL(new Blob([out], { type: 'application/pdf' }));
            this.loading=false;
            this.modalClose();
            this.modalOpen('ModalPdfViewer',file)
        }
    });
}

const downloadFile = async function (file) {
    this.file.url = '';

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'pratiche/downloadfile',
        params: {
            c_pratica: file.c_pratica,
            c_pratica_guid: file.c_pratica_guid
        },
        userToken: this.userToken
    });

    runInAction(() => {
        if (response) {
            let bytes = atob(response.data);
            let length = bytes.length;
            let out = new Uint8Array(length);

            while (length--) {
                out[length] = bytes.charCodeAt(length);
            }

            let a = document.createElement("a");
            a.href = URL.createObjectURL(new Blob([out], { type: 'application/pdf' }));
            a.download = file.c_pratica+'.pdf';
            a.click();
            this.loading=false;
        }
    });
}

const uploadPicture = function (parmas,file) {
    let data = [];

    switch (parmas.view) {
        case 'reclamo':
            data = toJS(this.dataReclamo.pictures);
            break;
        case 'assistenza':
            data = toJS(this.dataAssistenza.allegati);
            break;
    }

    var reader = new FileReader();
    var source = "";
    var store = this;
    let size_enable = store.config.upload.max_size;
    let f = data.find((p) => p.name === file.name);

    if (f) {
        store.log('Image already loaded');
        store.notificationAdd(langstore.t("image_already_loaded", "Immagine già caricata"), "nuovoReclamoAddPictures", 2000, "error");
        return;
    }
    if (!validateExtensionImage(file.name)) {
        store.log('Extension image error');
        store.notificationAdd(langstore.t("reclami_imgformat_error", "Il file non è un'immagine"), "nuovoReclamoAddPictures", 2000, "error");
        return;
    }

    new Compressor(file,{
        quality:0.8,
        async success(result) {

            reader.onload = function (e) {

                source = e.target.result;

                if (result.size > size_enable){
                    store.log("Image too large");
                    store.notificationAdd(langstore.t("image_too_large", "La dimensione dell'immagine è troppo grande"), "nuovoReclamoAddPictures", 2000, "error");
                    return;
                }
                else {
                    if (store.total_size+result.size > size_enable){
                        store.log("Max size finished");
                        store.notificationAdd(langstore.t("max_size_finished", "Limite spazio raggiunto"), "nuovoReclamoAddPictures", 2000, "error");
                        return;
                    }
                    else {
                        runInAction(() => {
                            store.total_size = store.total_size + result.size
                        });
                    }
                }

                let item = {
                    source: source,
                    name: file.name,
                    size: result.size
                }

                data.push(item);

                runInAction(() => {
                    switch (parmas.view) {
                        case 'reclamo':
                            store.dataReclamo.pictures = data;
                            break;
                        case 'assistenza':
                            store.dataAssistenza.allegati = data;
                            break
                    }
                });
            }
            reader.readAsDataURL(result);
        },
        error(error) {
            store.log('Upload image error');
            store.notificationAdd(langstore.t("upload_image_error", "Errore nel caricamento dell'immagine"), "nuovoReclamoAddPictures", 2000, "error");
        }
    });
}

const removePicture = function (params, name) {
    let data = null;

    switch (params.view) {
        case 'assistenza':
            data = toJS(this.dataAssistenza.allegati);
            break;
        case 'reclamo':
            data = toJS(this.dataReclamo.pictures);
            break;
    }

    data = data.filter((item) => {
        return item.name !== name;
    });

    // calcolo lo spazio disponibile corrente
    let current_size=0;
    data.forEach((item) => {
        current_size = current_size + item.size;
    })

    runInAction(() => {
        this.total_size = current_size;
    });

    runInAction(() => {
        switch (params.view) {
            case 'assistenza':
                this.dataAssistenza.allegati = data;
                break;
            case 'reclamo':
                this.dataReclamo.pictures = data;
                break;
        }
    });

    // necessario azzerare stato input altrimenti non ricarica la stessa immagine
    // i dati immagine sono gestiti nello store e non nell'input
    let input = document.querySelector("#file_input");

    input.value = "";
}

export {
    showFile,
    downloadFile,
    uploadPicture,
    removePicture
}
