import React from 'react'
import ReactDOM from 'react-dom'
import {inject, observer} from 'mobx-react'
import {toJS} from 'mobx'
import Route from 'route-parser';
import Version from "System/components/version.jsx";

export default inject("rootstore", "langstore")(observer((props) => {

    let opens = toJS(props.rootstore.menusOpen);
    let menuGroups = ['main'];

    function clickMenuItem(route, expandTree) {
        const {actionType} = route;

        switch (actionType) {
            case 'none':
                if (expandTree !== false) {
                    props.rootstore.routesTreeToggle(route.idMenu);
                }
                break;
            case 'ssoredirect':
                props.rootstore.ssoRedirect(route.path, route.params)
                break;
            case 'externalurl':
                window.open(route.path, '_blank');
                break;
            default:
                if (expandTree !== false) {
                    props.rootstore.routesTreeOpen(route.idMenu);
                }
                props.rootstore.routeChangeWithLink(route.path, route.external, route.target, route.params);
        }
        if (props.rootstore.viewportCurrent === "mobile" || props.rootstore.viewportCurrent === "tablet") {
            props.rootstore.uiSidebarToggle()
        }
    }

    return (
        <div className="sidebar_menu_list">
            {menuGroups.map((group, group_i) => {

                let groupItems = props.rootstore.routesTreeCurrent[group];

                return (

                    <nav className="ts_sidebar_menu ts_sidebar_menu_span" key={group_i}>

                        {groupItems.map((route, i) => {

                            let isOpen = opens.indexOf(route.idMenu) >= 0;
                            let path = new Route(route.path);
                            let routeIsActive = (path.match(props.rootstore.route) !== false) || (route.children && route.children.filter(r => r.path === props.rootstore.route).length !== 0);
                            const menuItemIsOpenClass = isOpen ? "open" : "";
                            const routeIsActiveCLass = routeIsActive ? "active" : "";
                            const hasChildrenClass = route.children ? "has_children" : "";
                            const hasParentClass = route.parent ? "has_parent" : "";

                            return (
                                <div
                                    className={`ts_sidebar_item ${routeIsActiveCLass} ${hasChildrenClass} ${hasParentClass} ${menuItemIsOpenClass}`}
                                    key={route.idMenu}>
                                    <div
                                        className={`ts_sidebar_item_title d-flex align-items-center`}
                                        onClick={() => {
                                            if (route.children) {
                                                props.rootstore.routesTreeToggle(route.idMenu);
                                            } else {
                                                clickMenuItem(route)
                                            }
                                        }}>

                                        <div className="mr-auto">
                                            <i className={route.iconClass ? route.iconClass : "fa fa-folder"}/>
                                            <span
                                                className="ts_text2 ts_sidebar_item_label text-uppercase mr3">{props.langstore.t(route.titleKey, route.titleString)}</span>
                                        </div>

                                        {route.children &&
                                            <span className="has_child_arrow">+</span>
                                        }

                                        {route.counter && props.rootstore[route.counter] && props.rootstore[route.counter].length >= 1 &&
                                            <span
                                                className="ts_sidebar_count">{props.rootstore[route.counter].length}</span>
                                        }
                                    </div>

                                    {route.children &&
                                        <div
                                            className={isOpen === true ? "ts_sidebar_item_child open" : "ts_sidebar_item_child"}>
                                            {route.children.map((routeChild, ii) => {

                                                let path = new Route(routeChild.path);
                                                let routeChildIsActive = path.match(props.rootstore.route) !== false;

                                                return (
                                                    <div
                                                        key={routeChild.idMenu}
                                                        className={routeChildIsActive ? "ts_sidebar_item_title active" : "ts_sidebar_item_title"}
                                                        onClick={() => clickMenuItem(routeChild, false)}>
                                                    <span className="style_sidebar_subitem">
                                                        <i className="fas fa-circle"/>
                                                        <span>{props.langstore.t(routeChild.titleKey, routeChild.titleString)}</span>
                                                    </span>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    }
                                </div>
                            )
                        })}
                    </nav>
                )
            })}

            {props.rootstore.userToken ?
                <nav className="ts_sidebar_menu">

                    {props.rootstore.userInfo.internal === false && props.rootstore.viewportCurrent === 'mobile' &&
                        <div>
                            <div className="ts_sidebar_item">
                                <div className={"ts_sidebar_item_title"}
                                     onClick={
                                         () => {
                                             props.rootstore.routeChange("/comunicazioni");
                                             if (props.rootstore.viewportCurrent === "mobile" || props.rootstore.viewportCurrent === "tablet") {
                                                 props.rootstore.uiSidebarToggle();
                                             }
                                         }
                                     }>
                                    <i className="fa fa-bell"/>
                                    <span
                                        className="ts_text2 ts_sidebar_item_label">{props.langstore.t("comunicazioni", "COMUNICAZIONI")}</span>
                                </div>
                            </div>
                            <div className="ts_sidebar_item">
                                <div className={"ts_sidebar_item_title"}
                                     onClick={
                                         () => {
                                             props.rootstore.routeChange("/acl");
                                             if (props.rootstore.viewportCurrent === "mobile" || props.rootstore.viewportCurrent === "tablet") {
                                                 props.rootstore.uiSidebarToggle();
                                             }
                                         }
                                     }>
                                    <i className="fa fa-users"/>
                                    <span
                                        className="ts_text2 ts_sidebar_item_label">{props.langstore.t("multi_profilo", "MULTIPROFILO")}</span>
                                </div>
                            </div>
                        </div>
                    }

                    {props.rootstore.userInfo.internal === true && props.rootstore.userInfo.salesperson &&
                        <div>
                            <div className="ts_sidebar_item">
                                <div
                                    className={props.rootstore.routeIdCurrent === 'ordini' ? "ts_sidebar_item_title active" : "ts_sidebar_item_title"}
                                    onClick={
                                        () => {
                                            props.rootstore.routeChange("/ordini");
                                            if (props.rootstore.viewportCurrent === "mobile" || props.rootstore.viewportCurrent === "tablet") {
                                                props.rootstore.uiSidebarToggle();
                                            }
                                        }
                                    }>
                                    <i className="fas fa-cart-arrow-down"/>
                                    <span
                                        className="ts_text2 text-uppercase ts_sidebar_item_label">{props.langstore.t("ordini_archivio", "Archivio ordini")}</span>
                                </div>
                            </div>
                            <div className="ts_sidebar_item">
                                <div
                                    className={props.rootstore.routeIdCurrent === 'fatture' ? "ts_sidebar_item_title active" : "ts_sidebar_item_title"}
                                    onClick={
                                        () => {
                                            props.rootstore.routeChange("/fatture");
                                            if (props.rootstore.viewportCurrent === "mobile" || props.rootstore.viewportCurrent === "tablet") {
                                                props.rootstore.uiSidebarToggle();
                                            }
                                        }
                                    }>
                                    <i className="fas fa-file-invoice"/>
                                    <span
                                        className="ts_text2 text-uppercase ts_sidebar_item_label">{props.langstore.t("fatture_archivio", "Archivio fatture")}</span>
                                </div>
                            </div>
                            <div className="ts_sidebar_item">
                                <div
                                    className={props.rootstore.routeIdCurrent === 'sostituzioni' ? "ts_sidebar_item_title active" : "ts_sidebar_item_title"}
                                    onClick={
                                        () => {
                                            props.rootstore.routeChange("/post-vendita/sostituzioni");
                                            if (props.rootstore.viewportCurrent === "mobile" || props.rootstore.viewportCurrent === "tablet") {
                                                props.rootstore.uiSidebarToggle();
                                            }
                                        }
                                    }>
                                    <i className="fas fa-exchange-alt"/>
                                    <span
                                        className="ts_text2 text-uppercase ts_sidebar_item_label">{props.langstore.t("sostituzioni", "Sostituzioni")}</span>
                                </div>
                            </div>
                        </div>

                    }

                    <div className="ts_sidebar_item">
                        <div
                            className={props.rootstore.routeIdCurrent === 'userprofile' ? "ts_sidebar_item_title active" : "ts_sidebar_item_title"}
                            onClick={
                                () => {
                                    props.rootstore.routeChange("/profile");
                                    if (props.rootstore.viewportCurrent === "mobile" || props.rootstore.viewportCurrent === "tablet") {
                                        props.rootstore.uiSidebarToggle();
                                    }
                                }
                            }>
                            <i className="fas fa-user"/>
                            <span
                                className="ts_text2 ts_sidebar_item_label text-uppercase">{props.langstore.t("profilo", "Profilo")}</span>
                        </div>
                    </div>

                    <div className="ts_sidebar_item">
                        <div
                            className={"ts_sidebar_item_title"}
                            onClick={
                                () => {
                                    props.rootstore.userLogout();
                                    if (props.rootstore.viewportCurrent === "mobile" || props.rootstore.viewportCurrent === "tablet") {
                                        props.rootstore.uiSidebarToggle();
                                    }
                                }
                            }>
                            <i className="fa fa-sign-out-alt icon_m"/>
                            <span
                                className="ts_text2 ts_sidebar_item_label text-uppercase">{props.langstore.t("esci", "Esci")}</span>
                        </div>
                    </div>

                    {props.rootstore.userInfo.internal === false && props.rootstore.viewportCurrent === 'mobile' &&
                        <div>
                            <div className="divider-horizontal my-3"/>
                            <div className="options-mobile pb-4">

                                <div className="ts_sidebar_item">
                                    {props.langstore.t('last_update', 'ultimo aggiornamento')}: <span
                                    className="">{props.rootstore.last_update}</span>
                                </div>
                                <div className="ts_sidebar_item mt2">
                                    {props.langstore.t('next_update', 'prossimo aggiornamento')}: <span
                                    className="">{props.rootstore.next_update}</span>
                                </div>
                                <div className="ts_sidebar_item mt2">
                                    <Version/>
                                </div>
                            </div>
                        </div>
                    }

                </nav>

                :

                <nav className="ts_sidebar_menu">
                    <div className="ts_sidebar_item">
                        <div
                            className={props.rootstore.routeIdCurrent === 'userlogin' ? "ts_sidebar_item_title active" : "ts_sidebar_item_title"}
                            onClick={() => props.rootstore.routeChange("/login")}>
                            <i className="fas fa-user"/>
                            <span
                                className="ts_text2 ts_sidebar_item_label text-uppercase">{props.langstore.t("login", "Accedi")}</span>
                        </div>
                    </div>
                </nav>
            }
        </div>
    )
}));

