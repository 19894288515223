import {runInAction} from "mobx";

const createContact = async function (params, options) {
    // API call
    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'cliente/contatto',
        cacheAge: options && options.cacheAge ? options.cacheAge : this.config.cacheAPI.default,
        params: params,
        userToken: this.userToken
    });

    return response;
}

export {
    createContact
}