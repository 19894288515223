import React from 'react'
import ReactDOM from 'react-dom'
import {inject, observer} from 'mobx-react'
import {toJS} from 'mobx';

// error boundary
import {ErrorBoundary} from '../components/errors.jsx';

// views
import views from "../../system/views.js";
import viewsCustom from "../../application/views.js";

// modals
import modals from "../../system/modals.js";
import modalsCustom from "../../application/modals.js";

// overwrite system with application
Object.assign(views, viewsCustom);
Object.assign(modals, modalsCustom);


const RouterContent = inject('rootstore')(observer((props) => {

    const routes = props.rootstore.routes;

    return (
        <span>
            {routes.map((route) => {
                if (props.rootstore.routeIdCurrent === route.id) {
                    const ViewCurrent = views[route.content];
                    return (
                        <div className="ts_view" key={route.id + JSON.stringify(props.rootstore.routeParamsCurrent)}>
                            <ErrorBoundary>
                                <ViewCurrent/>
                            </ErrorBoundary>
                        </div>
                    )
                }
            })}
        </span>
    )
}));


const RouterNavigation = inject('rootstore')(observer((props) => {

    const routes = props.rootstore.routes;

    return (
        <span className="text-uppercase">
            {routes.map((route) => {
                if (props.rootstore.routeParamsCurrent.routeId === route.id) {
                    const ViewCurrent = route.navigation && views[route.navigation];
                    if (ViewCurrent) {
                        return (
                            <ViewCurrent key={route.id + JSON.stringify(props.rootstore.routeParams)}/>
                        )
                    }
                }
            })}
        </span>
    )
}));

const RouterSidebar = inject('rootstore')(observer((props) => {

    const routes = props.rootstore.routes;
    let routeCurrent = routes.find((route) => {
        return props.rootstore.routeParamsCurrent.routeId === route.id
    });
    const ViewCurrent = routeCurrent.sidebar && views[routeCurrent.sidebar];

    return (
        <ViewCurrent/>
    )
}));

const RouterPopup = inject('rootstore')(observer((props) => {
    const modalItems = toJS(props.rootstore.modalItems);
    let modalCss = 'ts_modal_frame';

    if (modalItems.length>0 && modalItems[0].params){
        switch (modalItems[0].params.type) {
            case 'document':
                modalCss = 'ts_modal_frame_fullscreen'
                break;
            case 'video':
                modalCss = 'ts_modal_frame_video'
                break;
            default:
                modalCss = 'ts_modal_frame'
                break;
        }
    }

    let modalItem = null;
    let ModalView = null;

    if (modalItems.length >= 1) {
        modalItem = modalItems[0];
        ModalView = modals[modalItem.component];
    }

    return (
        <div>
            {modalItems.length >= 1 &&
                <div className="ts_modal_wrap" key={modalItem.component}>
                    <div className="ts_modal_back" onClick={() => {
                        props.rootstore.modalCloseAll()
                    }}/>
                    <div className={modalCss}>
                        <ModalView params={modalItem.params}/>
                    </div>
                    {/*{fullScreen === true ? (
                        <div className="ts_modal_frame_fullscreen">
                            <ModalView params={modalItem.params}/>
                        </div>
                    ) : (
                        <div className="ts_modal_frame">
                            <ModalView params={modalItem.params}/>
                        </div>
                    )}*/}
                </div>
            }
        </div>
    )
}));

const OLD_RouterPopup = inject('rootstore')(observer((props) => {

    // creare configurazione popup

    const modalItems = toJS(props.rootstore.modalItems);
    let modalItem = null;
    let ModalView = null;

    if (modalItems.length >= 1) {
        modalItem = modalItems[0];
        ModalView = modals[modalItem.component];
    }

    return (
        <div>
            {modalItems.length >= 1 &&
                <div className="ts_modal_wrap" key={modalItem.component}>
                    <div className="ts_modal_back" onClick={() => {
                        props.rootstore.modalCloseAll()
                    }}/>
                    <div className="ts_modal_frame">
                        <ModalView params={modalItem.params}/>
                    </div>
                </div>
            }
        </div>
    )

}));

export {RouterContent, RouterNavigation, RouterSidebar, RouterPopup}

