import { toJS, runInAction } from "mobx";
import { currencyFormat } from "../../system/utilities/currency.js";
import langstore from "../../system/i18n/translator.js";
import moment from "moment";
import { dateFormat } from "System/utilities/time";
import { routeParamsCacheSave2 } from "../../system/actions/routes";

const otpCheckRetrieveDetails = async function () {
  let loadingKey = "otpCheckRetrieveDetails";
  let url = this.config.paths.apiURL + ( this.routeParamsCurrent.routeObject.id.startsWith('otp-co') ? 'ordine' : 'aap' ) + '-conferma-otp';
  let token = window.location.href.split("/").at(-1);
  if(!this.appReady) {
    // for backwards compatibility need to check if second last url part is a lang iso2 code or not
    const urlToInspect = window.location.href.split("/").at(-2).toLowerCase();
    this.language = urlToInspect.length === 2 ? urlToInspect : this.config.languages.languageDefault;
  }

  let _params = {
    c_guid_link: token
  };

  this.loadingAdd(loadingKey);

  let response = await this.dataLayer({
    url: url,
    cacheAge: 0,
    params: _params,
  });

  setTimeout(() => {
    this.loadingRemove(loadingKey);
  }, 1000);
  runInAction(() => {
    if (response) {
      let code = response.data.status_code;
      let r = response.data.ordine_conferma_otp;
      this.verificaOtp.type = 'co';
      if ( response.data.aap_conferma_otp ) {
        r = response.data.aap_conferma_otp;
        this.verificaOtp.type = 'aap';
      }
      this.verificaOtp.statoRisposta = code;
      this.verificaOtp.statoRecord = r.stato_record;
      this.verificaOtp.chiaveOutput = r.chiave_output;
      if (r.stato_record === 'OK') {
        this.verificaOtp.nomeRiferimento = r.riferimento_cliente;
        this.verificaOtp.nomeCliente = r.ragione_sociale;
        this.verificaOtp.idOrdine = `${r.n_anno}-${r.c_numeratore}-${r.n_ordine}`;
        this.verificaOtp.idReclamo = `${r.n_anno_reclamo}-${r.n_reclamo}`;
      }
    }
  });
};

const formOtpSubmit = async function () {
  if (this.verificaOtp.otp.length > 3) {
    let loadingKey = "formOtpSubmit";
    let url = this.config.paths.apiURL + (this.verificaOtp.type === 'aap' ? 'aap' : 'ordine') + '-conferma-otp/update';
    let token = window.location.href.split("/").pop();

    let _params = {
      c_guid_link: token,
      n_pin: this.verificaOtp.otp,
      input_utente: this.verificaOtp.userResponse ?? null,
      input_utente_nota: this.verificaOtp.rejectNotes ?? null
    };

    this.loadingAdd(loadingKey);

    let response = await this.dataLayer({
      url: url,
      cacheAge: 0,
      params: _params,
    });

    setTimeout(() => {
      this.loadingRemove(loadingKey);
    }, 1000);
    runInAction(()=>{
      if (response) {
        let code = response.data.status_code;
        let r = response.data.ordine_conferma_otp;
        this.verificaOtp.type = 'co';
        if ( response.data.aap_conferma_otp ) {
          r = response.data.aap_conferma_otp;
          this.verificaOtp.type = 'aap';
        }
        this.verificaOtp.statoRisposta = code;
        this.verificaOtp.statoRecord = r.stato_record;
        this.verificaOtp.chiaveOutput = r.chiave_output;
        if (r.stato_record === 'OK') {
          this.verificaOtp.nomeRiferimento = r.riferimento_cliente;
          this.verificaOtp.nomeCliente = r.ragione_sociale;
          this.verificaOtp.idOrdine = `${r.n_anno}-${r.c_numeratore}-${r.n_ordine}`;
          this.verificaOtp.idReclamo = `${r.n_anno_reclamo}-${r.n_reclamo}`;
          localStorage.removeItem('aap_rejected_notes_' + token);
        }
      }
    });
  }
};

const otpUpdate = function (val) {
  runInAction(() => {
    this.verificaOtp.otp = val;
  });
};

const aapOtpChoice = function (val) {
  runInAction(() => {
    this.verificaOtp.userResponse = val;
  });
};

const aapOtpRejectNotes = function (val) {
  runInAction(() => {
    this.verificaOtp.rejectNotes = val;
  });
};

const portafoglioordini = async function (params, options) {
  let loadingKey = "portafoglioordini";
  let localParams = this.routeParamsCacheGet("portafoglioordiniParams");
  let dates = this.rangeToDates(this.config.format.dateRangeDefault);

  let _params = {
    PageNumber: localParams.PageNumber || 1,
    da_dt_ordine_cliente: localParams.da_dt_ordine_cliente || dates.fr,
    a_dt_ordine_cliente: localParams.a_dt_ordine_cliente || dates.to,
    route: "ordine/portafoglio",
  };

  this.loadingAdd(loadingKey);

  runInAction(() => {
    this.routeParamsCacheSave2("portafoglioordiniParams", _params);
    this.loadingRemove(loadingKey);
  });
};

const orders = async function (params, options) {
  let loadingKey = "orderParams";
  let localParams = this.routeParamsCacheGet("orderParams");
  let dates = this.rangeToDates(this.config.format.dateRangeDefault);

  let _params = {
    PageNumber: localParams.PageNumber || 1,
    da_dt_ordine_cliente: localParams.da_dt_ordine_cliente || dates.fr,
    a_dt_ordine_cliente: localParams.a_dt_ordine_cliente || dates.to,
    route: "ordine/attivi",
  };

  // spinner
  this.loadingAdd(loadingKey);
  //await this.updateEventiOrdini();

  // business logic
  runInAction(() => {
    this.routeParamsCacheSave2("orderParams", _params);
    this.loadingRemove(loadingKey);
  });
};

const ordiniCampioni = async function (params, options) {
  let loadingKey = "ordersOrdiniCampioni";
  let localParams = this.routeParamsCacheGet("ordiniCampioniParams");
  let dates = this.rangeToDates(this.config.format.dateRangeDefault);

  let _params = {
    PageNumber: localParams.PageNumber || 1,
    da_dt_ordine_cliente: localParams.da_dt_ordine_cliente || dates.fr,
    a_dt_ordine_cliente: localParams.a_dt_ordine_cliente || dates.to,
    route: "ordine/campioni",
  };

  this.loadingAdd(loadingKey);

  runInAction(() => {
    this.routeParamsCacheSave2("ordiniCampioniParams", _params);
    this.loadingRemove(loadingKey);
  });
};

const ordiniNoteAssistenza = async function (params, options) {
  let loadingKey = "ordersOrdiniNoteAssistenza";
  let localParams = this.routeParamsCacheGet("ordiniNoteAssistenzaParams");
  let dates = this.rangeToDates(this.config.format.dateRangeDefault);

  let _params = {
    PageNumber: localParams.PageNumber || 1,
    da_dt_ordine_cliente: localParams.da_dt_ordine_cliente || dates.fr,
    a_dt_ordine_cliente: localParams.a_dt_ordine_cliente || dates.to,
    route: "ordine/noteassistenza",
  };

  this.loadingAdd(loadingKey);

  runInAction(() => {
    this.routeParamsCacheSave2("ordiniNoteAssistenzaParams", _params);
    this.loadingRemove(loadingKey);
  });
};

const previsioneSpedizioni = async function (params, options) {
  let loadingKey = "orderParams";
  let localParams = this.routeParamsCacheGet("orderParams");

  let _params = {
    PageNumber: localParams.PageNumber || 1,
    route: "ordine/previsionespedizioni",
  };

  this.loadingAdd(loadingKey);

  runInAction(() => {
    this.routeParamsCacheSave2("orderParams", _params);
    this.loadingRemove(loadingKey);
  });
};

const orderExpo = async function (params, options) {
  let loadingKey = "ordersOrdiniExpo";
  let localParams = this.routeParamsCacheGet("ordiniExpoParams");
  let dates = this.rangeToDates(this.config.format.dateRangeDefault);

  let _params = {
    PageNumber: localParams.PageNumber || 1,
    da_dt_ordine_cliente: localParams.da_dt_ordine_cliente || dates.fr,
    a_dt_ordine_cliente: localParams.a_dt_ordine_cliente || dates.to,
    route: "ordine/expo",
  };

  this.loadingAdd(loadingKey);

  runInAction(() => {
    this.routeParamsCacheSave2("ordiniExpoParams", _params);
    this.loadingRemove(loadingKey);
  });
};

const sostituzioni = async function (params, options) {
  let loadingKey = "ordersSostituzioni";
  let localParams = this.routeParamsCacheGet("sostituzioniParams");
  let dates = this.rangeToDates(this.config.format.dateRangeDefault);

  let _params = {
    PageNumber: localParams.PageNumber || 1,
    da_dt_ordine_cliente: localParams.da_dt_ordine_cliente || dates.fr,
    a_dt_ordine_cliente: localParams.a_dt_ordine_cliente || dates.to,
    route: "ordine/sostituzioni",
  };

  this.loadingAdd(loadingKey);

  runInAction(() => {
    this.routeParamsCacheSave2("sostituzioniParams", _params);
    this.loadingRemove(loadingKey);
  });
};

const sostituzioniAttive = async function (params, options) {
  let loadingKey = "ordersSostituzioniAttive";
  let localParams = this.routeParamsCacheGet("sostituzioniAttiveParams");
  let dates = this.rangeToDates(this.config.format.dateRangeDefault);

  let _params = {
    PageNumber: localParams.PageNumber || 1,
    da_dt_ordine_cliente: localParams.da_dt_ordine_cliente || dates.fr,
    a_dt_ordine_cliente: localParams.a_dt_ordine_cliente || dates.to,
    route: "ordine/sostituzioni/attive",
  };

  this.loadingAdd(loadingKey);

  runInAction(() => {
    this.routeParamsCacheSave2("sostituzioniAttiveParams", _params);
    this.loadingRemove(loadingKey);
  });
};

const orderdetail = async function (params, options) {
  const loadingKey = "orderDetail";
  this.loadingAdd(loadingKey);

  let _params = {
    n_anno: parseInt(this.routeParamsCurrent.orderYear),
    c_numeratore: this.routeParamsCurrent.orderNumber,
    n_ordine: parseInt(this.routeParamsCurrent.orderId),
  };

  let response = await this.dataLayer({
    url: this.config.paths.apiURL + "ordine/dettaglio",
    cacheAge:
      options && options.cacheAge
        ? options.cacheAge
        : this.config.cacheAPI.default,
    params: _params,
    userToken: this.userToken,
  });

  runInAction(() => {
    if (response) {
      this.dataOrder = response.data;
    }
    this.loadingRemove(loadingKey);
    this.loading = false;
  });
};

const getOrderReclami = async function () {
  let dates = this.rangeToDates(this.config.format.dateRangeDefault);

  let _params2 = {
    PageNumber: 1,
    da_dt_effettiva_reclamo: dates.fr,
    a_dt_effettiva_reclamo: dates.to,
  };

  let response2 = await this.dataLayer({
    url: this.config.paths.apiURL + "reclami",
    cacheAge: this.config.cacheAPI.default,
    params: _params2,
    userToken: this.userToken,
  });

  if (response2) {
    this.dataOrderReclami = response2.data;
  }
};

const addCurrency = (object, childPropName) => {
  const _object = { ...object };

  if (_object[childPropName]) {
    // if child actually exists
    _object[childPropName].DS_im_lordo = _object[childPropName].im_lordo
      ? currencyFormat(
          _object[childPropName].im_lordo,
          _object[childPropName].c_valuta
        )
      : "--";
    _object[childPropName].DS_im_netto = _object[childPropName].im_netto
      ? currencyFormat(
          _object[childPropName].im_netto,
          _object[childPropName].c_valuta
        )
      : "--";
    _object[childPropName].DS_im_totale = _object[childPropName].im_totale
      ? currencyFormat(
          _object[childPropName].im_totale,
          _object[childPropName].c_valuta
        )
      : "--";
  } else {
    _object.DS_im_lordo = _object.im_lordo
      ? currencyFormat(_object.im_lordo, _object.c_valuta)
      : "--";
    _object.DS_im_netto = _object.im_netto
      ? currencyFormat(_object.im_netto, _object.c_valuta)
      : "--";
    _object.DS_im_netto = _object.im_totale
      ? currencyFormat(_object.im_totale, _object.c_valuta)
      : "--";
  }

  return _object;
};

const relations = async function (params, options) {
  let loadingKey = "getRelations";

  if (!(options && options.noLoad === true)) {
    // spinner
    this.loadingAdd(loadingKey);
  }

  let _params = {
    anno: parseInt(params.n_anno),
    numeratore: params.c_numeratore,
    numero: parseInt(params.n_ordine),
    calcolo_dati: params.calcolo_dati,
    dati_ulteriori: params.dati_ulteriori ? true : false,
    visualizza_dati: "sunkey",
    utente_esecuzione: "",
  };

  // API call
  let response = await this.dataLayer({
    url: this.config.paths.apiURL + "common/relazionibof",
    cacheAge:
      options && options.cacheAge
        ? options.cacheAge
        : this.config.cacheAPI.default,
    params: _params,
    userToken: this.userToken,
  });

  // business logic
  runInAction(() => {
    if (response) {
      const data = {
        ...response.data,
      };

      if (data.dati_ulteriori) {
        data.dati_ulteriori = data.dati_ulteriori.map((d) => {
          return {
            ...d,
            DS_im_importo: currencyFormat(d.im_importo, d.c_valuta),
          };
        });
      }

      this.dataOrderRelations = data;
    }
    this.loadingRemove(loadingKey);
  });
};

const fileListModalOpen = function (docs) {
  runInAction(() => {
    this.dataDocumentInfo = {};
    this.dataDocumentActive = docs;
    this.modalOpen("ModalPratiche");
  });
};

const fileList = async function (params, options) {
  let _params = {
    c_pratica: params.c_pratica,
    c_pratica_guid: params.c_pratica_guid,
  };

  let files = toJS(this.dataDocumentInfo);
  let index = params.c_pratica_guid;
  files[index] = {
    loadingState: "loading",
    data: [],
  };
  this.dataDocumentInfo = files;

  // API call
  let response = await this.dataLayer({
    url: this.config.paths.apiURL + "pratiche/infofiles",
    cacheAge:
      options && options.cacheAge
        ? options.cacheAge
        : this.config.cacheAPI.default,
    params: _params,
    userToken: this.userToken,
  });

  let sorted = response.data && response.data.info ? response.data.info : [];
  sorted.sort((a, b) => {
    return a.c_versione - b.c_versione || a.c_riga - b.c_riga;
  });

  // business logic
  runInAction(() => {
    if (response) {
      files[index] = {
        loadingState: "complete",
        data: sorted,
      };
      this.dataDocumentInfo = files;
    }
  });
};

const confermaOrdine = async function (params) {
  let _params = {
    n_anno: params.n_anno,
    c_numeratore: params.c_numeratore,
    n_ordine: params.n_ordine,
  };

  let response = await this.dataLayer({
    url: this.config.paths.apiURL + "ordine/conferma",
    cacheAge: 0,
    params: _params,
    userToken: this.userToken,
  });

  if (response.data === true) {
    this.notificationAdd(
      langstore.t("order_confirm_success", "Conferma inviata"),
      "orderConfirmSuccess",
      2000,
      "success"
    );
  } else {
    this.notificationAdd(
      langstore.t("order_confirm_fail", "Errore"),
      "orderConfirmFail",
      2000,
      "error"
    );
  }

  this.routeReload();
  this.modalClose();
  this.loading = false;
};

const confermaOrdineRapida = async function (params) {
  this.loading = true;

  if (!params.pin) {
    this.notificationAdd(
      langstore.t("order_confirm_fail", "Errore"),
      "orderConfirmFail",
      2000,
      "error"
    );
    this.loading = false;
    return;
  }

  let _params = {
    pin: params.pin,
    n_anno: params.n_anno,
    c_numeratore: params.c_numeratore,
    n_ordine: params.n_ordine,
  };

  let response = await this.dataLayer({
    url: this.config.paths.apiURL + "ordine/conferma",
    cacheAge: 0,
    params: _params,
    userToken: this.userToken,
  });

  if (response && response.data === true) {
    this.notificationAdd(
      langstore.t("order_confirm_success", "Conferma inviata"),
      "orderConfirmSuccess",
      2000,
      "success"
    );
    this.routeReload();
    this.modalClose();
    this.loading = false;
    return true;
  } else {
    this.loading = false;
    return false;
  }
};

export {
  otpCheckRetrieveDetails,
  otpUpdate,
  aapOtpChoice,
  aapOtpRejectNotes,
  formOtpSubmit,
  portafoglioordini,
  orders,
  ordiniCampioni,
  ordiniNoteAssistenza,
  previsioneSpedizioni,
  orderExpo,
  sostituzioni,
  sostituzioniAttive,
  orderdetail,
  getOrderReclami,
  relations,
  fileListModalOpen,
  fileList,
  confermaOrdine,
  confermaOrdineRapida,
};
