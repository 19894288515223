import {runInAction} from 'mobx';

const promotionsList = async function (params, options) {
    let loadingKey = 'promoList';

    let _params = {};

    // spinner
    this.loadingAdd(loadingKey);

    // API call
    let response = await this.dataLayer({
        url: this.config.paths.apiURL + '/promozioni',
        cacheAge: options && options.cacheAge ? options.cacheAge : this.config.cacheAPI.default,
        params: _params,
        userToken: this.userToken
    });

    // business logic
    runInAction(() => {
        if (response) {
            this.dataPromo.data = response.data;
        }
        this.loadingRemove(loadingKey);
    });
};

const getPromoFile = async function (params, options) {
    let loadingKey = 'promoFile';

    // spinner
    this.loadingAdd(loadingKey);

    // API call
    let response = await this.dataLayer({
        url: this.config.paths.apiURL + '/promozioni/' + params.c_promo + "/file",
        cacheAge: options && options.cacheAge ? options.cacheAge : this.config.cacheAPI.default,
        params: params,
        userToken: this.userToken
    });

    // business logic
    runInAction(() => {
        if (response && response.data) {
            let pdfBlob = dataURItoBlob('data:application/pdf;base64,' + response.data);

            const url = window.URL.createObjectURL(pdfBlob);

            window.open(url, '_blank');
        }
        this.loadingRemove(loadingKey);
    });
}

const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1])
    const mimeString = dataURI
        .split(',')[0]
        .split(':')[1]
        .split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
    }

    // write the ArrayBuffer to a blob, and you're done
    return new Blob([ab], {type: mimeString})
}

export {
    promotionsList,
    getPromoFile
}