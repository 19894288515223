import {runInAction, toJS} from 'mobx'
import langstore from "System/i18n/translator";
import Compressor from "compressorjs";

const sizeFromBytes = function (size) {
    let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let k = 1024;
    let bytes = size;
    let dm = 2;
    let i = 0;

    i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const sizeFromBytesArray = function (_downloads) {
    let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let k = 1024;
    let bytes = 0;
    let dm = 2;
    let i = 0;

    let downloads = toJS(_downloads);

    for (var ii = 0; ii < downloads.length; ii++) {
        let d = downloads[ii];
        bytes += d.size;
    }

    i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const validateExtensionImage = function (filename) {
    var allowedFiles = [".jpg", ".jpeg", ".png"];
    var regex = new RegExp("([a-zA-Z0-9()\s_\\.\-:]\)+(" + allowedFiles.join('|') + ")$");
    if (!regex.test(filename.toLowerCase())) {
        return false;
    }
    return true;
}

export {
    sizeFromBytes,
    sizeFromBytesArray,
    validateExtensionImage
}