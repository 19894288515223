// autorun: send statistics to server
// params: store

import { autorun, extendObservable, toJS } from "mobx"

export default function(_this) {
	return false;
  // autorun( ()=> {

  //   var userToken = _this.userToken;
  //   var routeParamsCurrent = _this.routeParamsCurrent;
  //   var browser = window.navigator;
  //   var date = new Date().toLocaleString();

  //   // todo: send data
  //   console.log("stats syncing");


  // })

}