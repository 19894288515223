import {runInAction} from 'mobx';
import React, {useRef} from 'react';
import langstore from '../../system/i18n/translator.js';

const uiChangeFilter = function (filter) {
    runInAction(() => {
        this.uiFilter = filter;
        this.notificationAdd(langstore.t("profile_settingsaved", "Impostazione salvata"), 'uifilter');
    });
}

const uiChangeTableFontSize = function (size) {
    runInAction(() => {
        this.uiTableFontSize = size;
        this.notificationAdd(langstore.t("profile_settingsaved", "Impostazione salvata"), 'tablefontsize');
    });
}

const uiSidebarToggle = function (bool) {

    let body = document.body;

    let state = !this.uiSidebarExpanded;
    if (typeof bool != 'undefined') {
        state = bool;
    }

    runInAction(() => {

        this.uiSidebarExpanded = state;

        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 10);
    });
}


const uiAreasToggle = function () {
    runInAction(() => {
        this.uiAreasExpanded = !this.uiAreasExpanded;
    });
}

const uiViewModeChange = function (mode) {
    runInAction(() => {
        this.uiViewMode = mode;
    });
}

const uiGridOptionsToggle = function () {
    runInAction(() => {
        let uiGridOptionsExpanded = this.uiGridOptionsExpanded;
        this.uiGridOptionsExpanded = !uiGridOptionsExpanded;
    });
}

const uiScrollTop = function (selector) {
    document.querySelector(selector).scrollTop = 0
}

const uiScrollInfoUpdate = function (px) {
    //console.log('scroll');
    this.uiScrollInfo = 50 * Math.floor(px / 50);
}


export {
    uiSidebarToggle,
    uiAreasToggle,
    uiViewModeChange,
    uiGridOptionsToggle,
    uiChangeTableFontSize,
    uiScrollTop,
    uiScrollInfoUpdate,
    uiChangeFilter
}