import React from 'react';
import {inject, observer} from 'mobx-react';
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from 'react-perfect-scrollbar'
import Wip from './wip.jsx';

// Devextreme
import itMessages from "devextreme/localization/messages/it.json";
import frMessages from "devextreme/localization/messages/fr.json";
import deMessages from "devextreme/localization/messages/de.json";
import nlMessages from "devextreme/localization/messages/nl.json";
import esMessages from "devextreme/localization/messages/es.json";

import {locale, loadMessages, formatMessage} from 'devextreme/localization';

// Routers
import {RouterContent, RouterNavigation, RouterSidebar, RouterPopup} from '../components/router.jsx';

// Views
import Notifications from '../components/notifications.jsx';
import UserOptions from '../components/useroptions.jsx';
import Offline from '../components/offline.jsx';
import SidebarMobile from '../components/sidebarmobile.jsx';

const Base = inject("rootstore", "langstore")(observer(
    class Base extends React.Component {

        constructor(props) {
            super(props);
            loadMessages(itMessages);
            loadMessages(frMessages);
            loadMessages(deMessages);
            loadMessages(nlMessages);
            loadMessages(esMessages);
        }

        render() {

            let props = this.props;
            locale(props.rootstore.language || navigator.language || navigator.browserLanguage);
            let scrollInfo = props.rootstore.uiScrollInfo > 1 ? 'active' : 'inactive';

            const routes = props.rootstore.routes;
            const {routeId} = props.rootstore.routeParamsCurrent;

            const {
                navigation,
                sidebar
            } = routes.find(r => routeId === r.id) || {};

            let uiSidebarExpanded = props.rootstore.uiSidebarExpanded;

            if (props.rootstore.config.wip === true) {
                return (
                    <Wip/>
                )
            } else {

                return (

                    <div className={"ts_base " + props.rootstore.bodyClassCurrent}>
                        <RouterPopup/>
                        <Offline/>
                        <div className="row no-gutters ts_content_row">
                            {sidebar &&
                                <aside className={props.rootstore.uiSidebarExpanded===true ? "ts_sidebar col-md-3 col-12 ts_sidebar_expanded" : "ts_sidebar col ts_sidebar_minimized"}>
                                    <div className="ts_sidebar_header">
                                        <img
                                            onClick={() => props.rootstore.routeChange(props.rootstore.config.routes.index)}
                                            src={props.rootstore.config.paths.logoExpandedB}
                                            className={"d-md-block"}
                                            alt="Company Logo"/>
                                    </div>

                                    <div className="ts_sidebar_body">
                                        <PerfectScrollbar options={{ suppressScrollX:true }}>
                                            <RouterSidebar/>
                                        </PerfectScrollbar>
                                    </div>

                                    <nav className="ts_sidebar_menu fixed_sidebar_menu">
                                        <div className="ts_sidebar_item">
                                            <div className="ts_sidebar_item_inner">
                                                <div
                                                    className="ts_sidebar_item_title ts_sidebar_toggle"
                                                    onClick={() => props.rootstore.uiSidebarToggle()}>
                                                    <i className="fa fa-chevron-left icon_m"/>
                                                    {(uiSidebarExpanded) ?
                                                        <span className="ts_text2 ts_sidebar_item_label">{props.langstore.t("ui_minimize_sidebar", "Minimizza")}</span>
                                                        :
                                                        <span className="ts_text2 ts_sidebar_item_label">{props.langstore.t("ui_fixed_sidebar", "Blocca")}</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </nav>

                                </aside>
                            }
                            <section className="ts_body col-12 col-md">
                                {navigation &&
                                    <div className={"row no-gutters ts_content_header " + scrollInfo}>
                                        <div className="col d-none d-lg-flex align-items-center">
                                            <RouterNavigation/>
                                        </div>
                                        <div className="col mr1">
                                            <UserOptions/>
                                        </div>
                                    </div>
                                }
                                <div className={`ts_content_body ${navigation ? "" : "_disable-navigation"}`}>
                                    <RouterContent/>
                                    <Notifications/>
                                    <SidebarMobile/>
                                </div>
                            </section>
                        </div>
                        {navigation &&
                            <div className="base-overlay"/>
                        }
                    </div>
                )
            }
        }
    }
));

export default Base;
