import React from "react";
import {toJS, runInAction} from 'mobx';

const portafoglioreclami = async function (params, options) {
    let loadingKey = 'portafoglioreclami';
    let localParams = this.routeParamsCacheGet("portafoglioreclamiParams");
    let dates = this.rangeToDates(this.config.format.dateRangeDefault);

    let _params = {
        "PageNumber": localParams.PageNumber || 1,
        "da_dt_effettiva_reclamo": localParams.da_dt_effettiva_reclamo || dates.fr,
        "a_dt_effettiva_reclamo": localParams.a_dt_effettiva_reclamo || dates.to,
        'route': 'reclami'
    }

    this.loadingAdd(loadingKey);
    runInAction(() => {
        this.routeParamsCacheSave2('portafoglioreclamiParams', _params);
        this.loadingRemove(loadingKey);
    });
}

const dettaglioReclamo = async function ()
{
    let loadingKey = 'reclamiDetail';
    this.loadingAdd(loadingKey);

    let tmp = (this.routeParamsCurrent.reclamoId).split('_');
    let reclamo = tmp[0].split('-');
    let ordine = tmp[1].split('-');

    let _params = {
        n_anno_reclamo: parseInt(reclamo[0]),
        n_reclamo: parseInt(reclamo[1]),
        n_anno_ordine: parseInt(ordine[0]),
        c_numeratore_ordine: ordine[1],
        n_ordine: parseInt(ordine[2])
    }

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'reclami/reclamoDettaglio',
        cacheAge: 0,
        userToken: this.userToken,
        params: _params
    });

    runInAction(() => {
        if (response) {
            this.dataReclamoDettaglio = response.data
        }
        this.loadingRemove(loadingKey);
    });
}

const OLD_dettaglioReclamo = async function () {
    let loadingKey = 'reclamiDetail';
    this.loadingAdd(loadingKey);

    let _params = {
        n_anno_reclamo: parseInt((this.routeParamsCurrent.reclamoId).split('-')[0]),
        n_reclamo: parseInt((this.routeParamsCurrent.reclamoId).split('-')[1])
    }

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'reclami/reclamoDettaglio',
        cacheAge: 0,
        userToken: this.userToken,
        params: _params
    });

    runInAction(() => {
        if (response) {
            this.dataReclamoDettaglio = response.data
        }
        this.loadingRemove(loadingKey);
    });
}

const nuovoReclamoInit = async function () {
    await this.orderdetail();
    runInAction(() => {
        this.dataReclamo = {
            section: 1,
            rows: [],
            pictures: [],
            note: ""
        };
    });
}

const nuovoReclamoUpdate = function (key, value) {
    let data = toJS(this.dataReclamo);
    data[key] = value;

    runInAction(() => {
        this.dataReclamo = data;
    });

    if (key === "section") {
        setTimeout(() => {
            let element = document.querySelector(".ts_body ");
            element.scrollTop = element.scrollHeight;
        }, 1);
    }
}

// Azione combo: con parametro true mostra modale; altrimenti invia reclamo
const nuovoReclamoSend = async function (confirmModal) {
    if (confirmModal === true) {
        this.modalOpen("ModalConfirmReclamo");
    }
    else {
        let loadingKey = 'nuovoReclamoSend';
        this.loadingAdd(loadingKey);
        this.modalCloseAll();

        let data = toJS(this.dataReclamo);

        if(!data.pictures || data.pictures.length===0){
            this.loadingRemove(loadingKey);
            this.notificationAdd(this.props.langstore.t("reclamo_errore_immagini", "Devi caricare almeno un'immagine"), "reclamo_errore_righe", 2000, "error")
        }

        let _params = {
            'des_reclamo': data.note,
            'persona_riferimento': data.referent,
            'telefono_riferimento': data.referent_tel,
            'email_riferimento': data.referent_email,
            'file': data.pictures,
            'righe': data.rows,
            'titolo': this.dataOrder.dettaglio.riferimento_cliente
        };

        let response = await this.dataLayer({
            url: this.config.paths.apiURL + 'reclami/add',
            cacheAge: 0,
            userToken: this.userToken,
            params: _params
        });

        runInAction(() => {
            if (response) {
                this.modalOpen("ModalSuccessReclamo");
                this.routeChange("/ordini/dettaglio/"+data.rows[0].n_anno+'/'+data.rows[0].c_numeratore+'/'+data.rows[0].n_ordine);
            }
            else {
                this.modalOpen("ModalFailReclamo");
                this.routeChange("/ordini/dettaglio/"+data.rows[0].n_anno+'/'+data.rows[0].c_numeratore+'/'+data.rows[0].n_ordine);
            }
            this.loadingRemove(loadingKey);
            this.dataReclamo = {
                section: 1,
                rows: [],
                pictures: [],
                note: ""
            };
        });
    }
}

export {
    portafoglioreclami,
    dettaglioReclamo,
    nuovoReclamoInit,
    nuovoReclamoUpdate,
    nuovoReclamoSend
}