import {runInAction} from 'mobx';
import {ApolloClient, gql, InMemoryCache} from "@apollo/client";

const schedemontaggio = async function (params, options) {
  const client = new ApolloClient({
    uri: this.config.pim.baseurl + this.config.pim.manuali_montaggio.endpoint + '?apikey=' + this.config.pim.manuali_montaggio.apikey,
    cache: new InMemoryCache()
  });

  console.log('>>>>>>>>>');
  console.log(this.config.pim.baseurl + this.config.pim.manuali_montaggio.endpoint + '?apikey=' + this.config.pim.manuali_montaggio.apikey);

  const GET_CATEGORIES = gql`
      query getObjectFolder($id_folder: Int!, $defaultLanguage: String) {
          categories: getObjectFolder(
              id: $id_folder
              defaultLanguage: $defaultLanguage
          ) {
              id
              children(objectTypes: ["object"]) {
                  ... on object_ManualeDiMontaggioCategoria {
                      classname
                      id
                      title
                      image {
                          fullpath
                      }
                  }
              }
          }
      }
  `;

  let loadingKey = 'schedemontaggio';

  let variables = {
    id_folder: this.config.pim.manuali_montaggio.folder_id,
    defaultLanguage: this.language,
  };

  this.loadingAdd(loadingKey);

  let categories_query = await client.query({query: GET_CATEGORIES, variables})
    .then((res) => {
      return res.data.categories;
    })
    .catch((error) => console.log(error));

  runInAction(() => {
    if (categories_query) {
      this.dataSchedeMontaggioCategorie = categories_query;
    }
    this.loadingRemove(loadingKey);
  });
}

const schedemontaggiocategory = async function (params, options) {
  const client = new ApolloClient({
    uri: this.config.pim.baseurl + this.config.pim.manuali_montaggio.endpoint + '?apikey=' + this.config.pim.manuali_montaggio.apikey,
    cache: new InMemoryCache()
  });

  const GET_CATEGORY = gql`
      query getManualeDiMontaggioCategoria(
          $id_categoria: Int!
          $defaultLanguage: String
      ) {
          category: getManualeDiMontaggioCategoria(
              id: $id_categoria
              defaultLanguage: $defaultLanguage
          ) {
              id
              title
              childrenSortBy
              children(objectTypes: ["object"]) {
                  ... on object_ManualeDiMontaggioCategoria {
                      id
                      title
                      image {
                          fullpath
                      }
                      classname
                  }
                  ... on object_ManualeDiMontaggio {
                      id
                      title
                      file {
                          ...assetDetails
                      }
                      localized_file {
                          ...assetDetails
                      }
                      classname
                      order
                  }
              }
              parent {
                  ... on object_ManualeDiMontaggioCategoria {
                      id
                      title
                  }
              }
          }
      }

      fragment assetDetails on asset {
          id
          filename
          fullpath
          mimetype
          filesize
      }
  `;

  let loadingKey = 'schedemontaggiocategory';

  let variables = {
    id_categoria: parseInt(this.routeParamsCurrent.categoryID),
    defaultLanguage: this.language,
  };

  this.loadingAdd(loadingKey);

  let category_query = await client.query({query: GET_CATEGORY, variables})
    .then((res) => {
      return res.data.category;
    })
    .catch((error) => console.log(error));

  runInAction(() => {
    if (category_query) {
      this.dataSchedeMontaggioCategoria = category_query;

      this.dataSchedeMontaggioManuali = category_query.children
        .filter((res) => res.classname == "ManualeDiMontaggio")
        .sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0));
    }

    this.dataSchedeMontaggioSottoCategorie = category_query.children.filter(
      (res) => res.classname == "ManualeDiMontaggioCategoria"
    );

    this.loadingRemove(loadingKey);
  });
}

const schedaprodotto = async function (params, options) {
  const client = new ApolloClient({
    uri: this.config.pim.baseurl + this.config.pim.manuali_montaggio.endpoint + '?apikey=' + this.config.pim.manuali_montaggio.apikey,
    cache: new InMemoryCache()
  });

  const GET_PDF = gql`
      query getSchedaProdotto(
          $id: Int!
          $defaultLanguage: String
      ) {
          datasheet: getSchedaProdotto(
              id: $id
              defaultLanguage: $defaultLanguage
          ) {
              id
              file {
                  ... on asset {
                      fullpath
                  }
              }
              localized_file {
                  ... on asset {
                      fullpath
                  }
              }
          }
      }
  `;

  let loadingKey = 'schedaprodotto';

  let variables = {
    id: this.config.pim.scheda_prodotto.obj_id,
    defaultLanguage: this.language,
  };

  this.loadingAdd(loadingKey);

  let schedaprodotto_query = await client.query({query: GET_PDF, variables})
    .then((res) => {
      return res.data.datasheet;
    })
    .catch((error) => console.log(error));

  runInAction(() => {
    if (schedaprodotto_query) {
      this.dataSchedaProdotto = schedaprodotto_query;
    }
    this.loadingRemove(loadingKey);
  });
}

const pimPromo = async function (url) {
  const client = new ApolloClient({
    uri: this.config.pim.promo.endpoint + '?apikey=' + this.config.pim.promo.apikey,
    cache: new InMemoryCache()
  });

  const GET_PROMO = gql`
      query getPromozioneListing(
          $filter: String
          $defaultLanguage: String
      ) {
          promo: getPromozioneListing(
              filter: $filter
              defaultLanguage: $defaultLanguage
          ) {
              edges {
                  node {
                      id
                      c_promo
                      title
                      technical_description
                      commercial_description
                      featured_image{
                          fullpath
                      }
                      file_flyer{
                          ...fileFlyer
                      }
                      thumb_720
                  }
              }
              totalCount
          }
      }

      fragment fileFlyer on asset {
          id
          filename
          fullpath
          mimetype
          filesize
      }
  `;

  var variables = {
    filter: "{\"c_promo\": \"755\"}",
    defaultLanguage: this.language,
  };

  let response = await client.query({query: GET_PROMO, variables})
    .then((res) => {
      return res.data.promo;
    })
    .catch(error => console.log(error));

  runInAction(() => {
    if (response) {
      this.dataPromoDetail = response.edges[0].node;
    }
  });
}

const tutorial = async function (params) {

  const client = new ApolloClient({
    uri: this.config.pim.tutorial.endpoint + '?apikey=' + this.config.pim.tutorial.apikey,
    cache: new InMemoryCache()
  });

  let variables = {
    defaultLanguage: this.language,
  };

  const TUTORIAL = gql`
      query getTutorialListing($defaultLanguage: String) {
          tutorial: getTutorialListing(
              defaultLanguage: $defaultLanguage
          ) {
              edges {
                  node {
                      id
                      video {
                          ...videoTutorial
                      }
                      title
                      color
                  }
              }
              totalCount
          }
      }

      fragment videoTutorial on asset {
          id
          filename
          fullpath
          mimetype
          filesize
      }
  `;

  const GUIDE = gql`
      query getGuidaListing($defaultLanguage: String) {
          guide: getGuidaListing(
              defaultLanguage: $defaultLanguage
          ) {
              edges {
                  node {
                      id
                      chiave
                      title
                      description
                      file {
                          ...guida
                      }
                      file_localized {
                          ...guida
                      }
                  }
              }
          }
      }
      fragment guida on asset {
          id
          filename
          fullpath
          mimetype
          filesize
      }
  `;

  let response1 = await client.query({query: TUTORIAL, variables})
    .then((res) => {
      return res.data.tutorial.edges;
    })
    .catch(error => console.log(error));

  let response2 = await client.query({query: GUIDE, variables})
    .then((res) => {
      return res.data.guide.edges;
    })
    .catch(error => console.log(error));

  runInAction(() => {
    if (response1) {
      this.dataPim.tutorial = response1;
    }
    if (response2) {
      if (this.dataUserDetails.logo === '002') {
        response2 = response2.filter(item => item.node.chiave !== 'garanzia')
      }
      if (!this.dataAutorizzazioni.reclami_web.abilitato) {
        response2 = response2.filter(item => item.node.chiave !== 'reclamo')
      }
      this.dataPim.guide = response2;
    }
  });
}


const getJwt = async function (params) {
  const _params = {
    folder: params
  };

  const response = await this.dataLayer({
    url: this.config.paths.apiURL + 'pim/jwt',
    cacheAge: 0,
    params: _params,
    userToken: this.userToken
  });

  return response.data;
}

const getAssetsList = async function (params) {
  const _params = {
    path: params
  };

  if (params === '/protected/area download' || params === '/protected/listini') {
    _params.path = '/protected/area download|/protected/listini/' + this.dataUserDetails.c_area_listino;
  }

  const response = await this.dataLayer({
    url: this.config.paths.apiURL + 'pim/list',
    cacheAge: 0,
    params: _params,
    userToken: this.userToken
  });

  return response.data;
};

const getAsset = async function (params) {
  let url = this.config.pim.baseurl + '/protected/' + params.asset;
  let jwt = await this.getJwt(params.root);

  let body = new FormData();
  body.append('token', jwt);

  let config = {
    method: 'POST',
    mode: 'cors',
    body: body
  };

  return await fetch(url, config)
    .then(response => response.body)
    .then(stream => new Response(stream, {headers: {"Content-Type": "application/pdf"}}))
    .then(response => response.blob())
    .then(blob => URL.createObjectURL(blob))
    .catch(error => console.log(error));
}

const getAssetVideo = async function (params) {
  const _params = {
    asset: params.asset,
    folder: params.root
  };

  const response = await this.dataLayer({
    url: this.config.paths.apiURL + 'pim/asset/video',
    cacheAge: 0,
    params: _params,
    userToken: this.userToken
  });

  return response.data.data.hasOwnProperty('mp4') ? response.data : null;
};


const getLastVersionUpdate = async function (params, options) {
  const client = new ApolloClient({
    uri: 'https://pim.primacucine.it/pimcore-graphql-webservices/customer-hub?apikey=1bde38e906584334ea2f7a1a1fafe362',
    cache: new InMemoryCache()
  });

  const GET_SOFTWARE_UPDATE = gql`
      query getAggiornamentoSoftware(
          $filter: String,
          $defaultLanguage: String
      ){
          getAggiornamentoSoftwareListing(
              defaultLanguage:$defaultLanguage,
              filter: $filter,
              sortBy:["o_id"],
              sortOrder:["DESC"],
              published: true,
              first:1
          ){
              totalCount,
              edges{
                  node{
                      id,
                      versione,
                      contenuto
                  }
              }
          }
      }
  `;

  let variables = {
    "filter": "{\"software\": \"customerhub\"}",
    defaultLanguage: this.language
  };

  return await client.query({query: GET_SOFTWARE_UPDATE, variables})
    .then((res) => {
      if (res.data && res.data.getAggiornamentoSoftwareListing && res.data.getAggiornamentoSoftwareListing.edges && res.data.getAggiornamentoSoftwareListing.edges.length > 0) {
        return res.data.getAggiornamentoSoftwareListing.edges[0].node;
      }
    })
    .catch(error => console.log(error));
}



export {
  schedemontaggio,
  schedemontaggiocategory,
  schedaprodotto,
  pimPromo,
  tutorial,
  getJwt,
  getAssetsList,
  getAsset,
  getAssetVideo,
  getLastVersionUpdate
}
