import React from 'react';
import {inject, observer} from 'mobx-react';
import Tooltip from 'rc-tooltip';
import {toJS} from 'mobx';
import Version from './version.jsx';
import Impersonification from "./impersonification.jsx";

export default inject("rootstore", "langstore")(observer((props) => {
    const {rootstore, langstore} = props;
    const isComunicazioniActive = rootstore.config && rootstore.config.comunicazioni === true;
    const isAclActive = rootstore.config && rootstore.config.aclEnabled === true;
    const viewAcl = ((props.rootstore.dataAutorizzazioni || {}).view_acl || {}).abilitato;
    const viewComunications = ((props.rootstore.dataAutorizzazioni || {}).view_comunications || {}).abilitato;

    const {
        comunicazioniUnread
    } = rootstore;

    const languages = toJS(rootstore.languages);

    let language_selected = '';
    languages.forEach((item) => {
        if (item.iso === rootstore.language) {
            language_selected = item.key;
        }
    });

    return (
        <div className="row align-items-center">

            <div className="col-2" id="MobileLogo">
                <img
                    onClick={() => {
                        //console.log('aaaa');
                        props.rootstore.routeChange(props.rootstore.config.routes.index)
                    }}
                    src={props.rootstore.config.paths.logoExpandedN}
                    className={"d-md-block logo-mobile"}
                    alt="Company Logo"/>
            </div>

            <div className="col">
                <div className="ts_user_info row">
                    <div className="col-12">
                        <div className="row align-items-center justify-content-end">

                            {props.rootstore.viewportCurrent !== 'mobile' ?

                                <div className="options-inline">

                                    <Version/>

                                    {rootstore.userInfo && rootstore.userInfo.impersonatingFrom &&
                                        <div className="options-inline">
                                            <div className="divider"/>
                                            <Impersonification/>
                                        </div>
                                    }

                                    {rootstore.userInfo.internal === false && props.rootstore.userToken !== null &&
                                        <div className="options-inline">
                                            <div className="divider"/>
                                            <div className="mr3 ml3">
                                                <div className="t1">{langstore.t('last_update', 'ultimo aggiornamento')}:
                                                    <span
                                                        className="font-weight-bold"> {props.rootstore.last_update}</span>
                                                </div>
                                                <div
                                                    className="t1">{langstore.t('next_update', 'prossimo aggiornamento')}:
                                                    <span
                                                        className="font-weight-bold"> {props.rootstore.next_update}</span>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className="options-inline">
                                        {props.rootstore.userToken !== null &&
                                            <div className="options-inline">

                                                <div className="divider"/>

                                                <div
                                                    className="ts_user_option ml3 mr3"
                                                    onClick={() => {
                                                        props.rootstore.routeChange("/profile")
                                                        if (props.rootstore.viewportCurrent === "mobile" || props.rootstore.viewportCurrent === "tablet") {
                                                            props.rootstore.uiSidebarToggle(false);
                                                        }
                                                    }
                                                    }>
                                                    <Tooltip overlay={langstore.t('profile', 'Gestione profilo')}
                                                             placement="bottom">
                                                        <i className="fa fa-user toolbar-icons"/>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        }

                                        {rootstore.userInfo.internal === false && isAclActive && viewAcl && props.rootstore.dataUserDetails.c_ruolo !== null && props.rootstore.dataUserDetails.c_ruolo === 'MASTER' &&
                                            <div className="options-inline">
                                                <div className="divider"/>
                                                <div
                                                    className="ts_user_option ml3 mr3"
                                                    onClick={() => {
                                                        props.rootstore.routeChange("/acl")
                                                        if (props.rootstore.viewportCurrent === "mobile" || props.rootstore.viewportCurrent === "tablet") {
                                                            props.rootstore.uiSidebarToggle(false);
                                                        }
                                                    }}>
                                                    <Tooltip overlay={langstore.t('gestione_utenti', 'Gestione utenti')}
                                                             placement="bottom">
                                                        <i className="fa fa-users toolbar-icons"/>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        }

                                        {rootstore.userInfo.internal === false && props.rootstore.userToken !== null && isComunicazioniActive && viewComunications && props.rootstore.viewportCurrent !== 'mobile' &&
                                            <div className="options-inline">
                                                <div className="divider"/>
                                                <div
                                                    className="ts_user_option ml3 mr3"
                                                    onClick={() => {
                                                        props.rootstore.routeChange("/comunicazioni")
                                                        if (props.rootstore.viewportCurrent === "mobile" || props.rootstore.viewportCurrent === "tablet") {
                                                            props.rootstore.uiSidebarToggle(false);
                                                        }
                                                    }}>
                                                    <Tooltip placement="bottom"
                                                             overlay={langstore.t('notifiche', 'Notifiche')}>
                                                        <i className="fa fa-bell toolbar-icons"/>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                :
                                <div className="options-inline">
                                    {/*<div className="lang-switcher">
                                        <div className="lang-selected">
                                            <div
                                                className="t2"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    props.rootstore.modalOpen("ModalLangSwitcher", null)
                                                }}>
                                                {language_selected}
                                            </div>
                                        </div>
                                    </div>*/}
                                    <div className="ts_user_option mobile-hamburger ml3"
                                         onClick={() => props.rootstore.uiSidebarToggle()}>
                                        <i className="fas fa-bars toolbar-icons"/>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}));


