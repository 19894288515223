import {runInAction, toJS} from "mobx";

const sendAssistance = async function (params) {
    params = toJS(params);

    for(let i in params){
        if (params[i] === ''){
            this.notificationAdd('Compila tutti i campi obbligatori','new-user-form-error',3000,'error');
            return;
        }
    }

    params.oggetto = 'PORTALE CLIENTI - RICHIESTA ASSISTENZA';
    params.allegati = toJS(this.dataAssistenza.allegati);

    const _params = {
        data: params
    };

    let response = await this.dataLayer({
        url: this.config.paths.apiURL + 'utility/assistenza',
        cacheAge: 1,
        params: _params,
        userToken: this.userToken
    });

    runInAction(() => {
        if (response) {
            this.dataAssistenza.allegati = [];
            this.modalOpen("ModalSuccessAssistenza");
        }
    });
}

export {
    sendAssistance
}