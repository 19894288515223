import {runInAction} from 'mobx';
import langstore from '../../system/i18n/translator.js';

const invoiceList = async function (params, options) {
	let loadingKey = 'invoiceList';
	let localParams = this.routeParamsCacheGet("invoicesParams");
	let dates = this.rangeToDates(this.config.format.dateRangeDefault);

	let _params = {
		"PageNumber": localParams.PageNumber || 1,
		"da_dt_fattura": localParams.da_dt_fattura || dates.fr,
		"a_dt_fattura": localParams.a_dt_fattura || dates.to,
		"tipi_fattura": localParams.tipi_fattura || [1, 3, 4, 5],
		'route': 'fattura'
	};

	this.loadingAdd(loadingKey);
	runInAction(() => {
		this.routeParamsCacheSave2('invoicesParams', _params);
		this.loadingRemove(loadingKey);
	});
};

const paymentList = async function (params, options) {

	let loadingKey = 'paymentlist';

	let localParams = this.routeParamsCacheGet("paymentsParams");
	let dates = this.rangeToDates(this.config.format.dateRangeDefault);

	let _params = {
		"PageNumber": localParams.PageNumber || 1,
		"da_dt_fattura": localParams.da_dt_fattura || dates.fr,
		"a_dt_fattura": localParams.a_dt_fattura || dates.to,
		"tipi_fattura": localParams.tipi_fattura || [2],
		'route': 'fattura/noteaccredito'
	};

	this.loadingAdd(loadingKey);

	runInAction(() => {
		this.routeParamsCacheSave2('paymentsParams', _params);
		this.loadingRemove(loadingKey);
	});
};

const autorizzazioniResoList = async function (params, options) {

	let loadingKey = 'autorizzazioniresolist';

	let localParams = this.routeParamsCacheGet("authResoParams");
	let dates = this.rangeToDates('month4');

	let _params = {
		"PageNumber": localParams.PageNumber || 1,
		"da_dt_fattura": dates.fr,
		"a_dt_fattura": dates.to,
		"tipi_fattura": localParams.tipi_fattura || [2],
		"route": "fattura/autorizzazioni-reso"
	};

	this.loadingAdd(loadingKey);

	runInAction(() => {
		this.routeParamsCacheSave2('authResoParams', _params);
		this.loadingRemove(loadingKey);
	});
};

/*
const invoiceDownload = async function (params) {

	let data = {
		n_anno_fattura: parseInt(params.n_anno_fattura_iterno),
		c_numeratore_fattura: params.c_numeratore_fattura_interno,
		n_fattura: parseInt(params.n_fattura_interno)
	}
	let p = {
		time: new Date().toLocaleString(),
		clientVersion: "0.0",
		data: data,
	}

	let xml = await fetch(this.config.paths.apiURL + '/fattura/elettronica/download', {
		method: "POST",
		body: JSON.stringify(p),
		headers: {
			'x-access-token': this.userToken,
			"Content-Type": "application/json"
		},
	});

	let headers = xml.headers;
	let blob = await xml.blob();
	let _filename = headers.get("Content-Disposition");
	let filename = _filename ? _filename.split("filename=").pop() : "temp";
};

const invoiceDownload_old = async function (params) {

	let data = {
		n_anno_fattura: parseInt(params.n_anno_fattura_iterno),
		c_numeratore_fattura: params.c_numeratore_fattura_interno,
		n_fattura: parseInt(params.n_fattura_interno)
	}
	let p = {
		time: new Date().toLocaleString(),
		clientVersion: "0.0",
		data: data,
	}

	let xml = await fetch(this.config.paths.apiURL + '/fattura/elettronica/download', {
		method: "POST",
		body: JSON.stringify(p),
		headers: {
			'x-access-token': this.userToken,
			"Content-Type": "application/json"
		},
	});

	let headers = xml.headers;
	let blob = await xml.blob();
	let _filename = headers.get("Content-Disposition");
	let filename = _filename ? _filename.split("filename=").pop() : "temp";

	if (blob.size < 200) {
		this.notificationAdd(langstore.t("file_invalid", "File non trovato"), "fileinvalid2", 3000, "error");
	} else {
		this.notificationAdd(langstore.t("download_ready", "Download pronto"), "fileinvalid1");
		saveData(blob, filename);
	}
};

var saveData = (function () {
	var a = document.createElement("a");
	document.body.appendChild(a);
	a.style.display = "none";
	return function (blob, fileName) {
		var url = window.URL.createObjectURL(blob);
		a.href = url;
		a.download = fileName;
		a.click();
		window.URL.revokeObjectURL(url);
	};
}());
*/

export {
	invoiceList,
	paymentList,
	autorizzazioniResoList
};