import langstore from "../../system/i18n/translator";
import {runInAction} from "mobx";

const sendWarranty = async function (params) {
    const _params = {
        'user': params.warranty,
        'ordine': params.order
    };

    if (_params.user.email === '' || typeof _params.user.email !== "string" || _params.user.email.indexOf("@") < 0) {
        this.modalClose();
        this.notificationAdd(langstore.t("garanzia_email_error", "Email non valida"), "orderGaranziaEmailError", 2000, "error");
        return;
    }

    let response = await this.dataLayer({
        url: _params.user.update===true ? this.config.paths.apiURL + 'garanzia/update' : this.config.paths.apiURL + 'garanzia',
        cacheAge: null,
        params: _params,
        userToken: this.userToken,
        method:  _params.user.update===true ? "PATCH" : "POST"
    });

    runInAction(() => {
       if (response) {
           this.loading=false;
           this.modalCloseAll();
           this.routeReload();
           if (_params.user.update===true){
               this.notificationAdd(langstore.t("garanzia_email_update_success", "Dati aggiornati"), "orderGaranziaEmail", 2000, "success");
           }
           else {
               this.notificationAdd(langstore.t("garanzia_email_success", "Garanzia richiesta"), "orderGaranziaEmail", 2000, "success");
           }
       }
       else {
           this.loading=false;
           this.modalCloseAll();
           this.routeReload();
       }
    });
};

export {
    sendWarranty
}