const menuIcons = {
  clienti: "fas fa-store-alt",
  ordini: "fas fa-cart-arrow-down",
  previsione_spedizioni: "fas fa-truck",
  post_vendita: "far fa-comment-dots",
  sostituzioni: "fas fa-exchange-alt",
  amministrazione: "fas fa-piggy-bank",
  expo: "fas fa-store",
  promozioni: "fas fa-percentage",
  area_tecnica: "fas fa-wrench",
  area_software: "fas fa-laptop",
  area_download: "fas fa-cloud-download-alt",
  primacucine: "fas fa-building",
  assistenza: "fas fa-question-circle",
  statistiche: "fas fa-chart-line",
  academy: "fas fa-graduation-cap"
};


export default menuIcons;