import Loadable from '@loadable/component';
import Loading from '../system/views/loading.jsx';

/** Dashboard */
const Dashboard = Loadable(() => import(/* webpackChunkName: "Dashboard" */ "./views/dashboard.jsx"));

/** Client section */
const ClientiArchivio = Loadable(() => import(/* webpackChunkName: "Clients" */ "./views/clienti/clientiArchivio.jsx"));
const ClientiDettaglio = Loadable(() => import(/* webpackChunkName: "ClientsDetail" */ "./views/clienti/clientiDettaglio.jsx"));

/** Orders section */
const OrdiniArchivio = Loadable(() => import(/* webpackChunkName: "Orders" */ "./views/ordini/ordiniArchivio.jsx"));
const OrdiniAttivi = Loadable(() => import(/* webpackChunkName: "ActiveOrders" */ "./views/ordini/ordiniAttivi.jsx"));
const OrdiniCampioni = Loadable(() => import(/* webpackChunkName: "SampleOrders" */ "./views/ordini/ordiniCampioni.jsx"));
const OrdiniNoteAssistenza = Loadable(() => import(/* webpackChunkName: "AssistanceNotes" */ "./views/ordini/noteassistenza.jsx"));
const OrdiniDettaglio = Loadable(() => import(/* webpackChunkName: "OrderDetail" */ "./views/ordini/dettaglio/ordiniDettaglio.jsx"));

/** Order confirmation OTP **/
const ConfermaOtpRichiestaConfermaOrdine = Loadable(() => import(/* webpackChunkName: "View20" */ "./views/standalone/richiestaConfermaOtp.jsx"));

/** AAP (Autorizzazione Al Pagamento) OTP **/
const ConfermaOtpAap = Loadable(() => import(/* webpackChunkName: "View20" */ "./views/standalone/richiestaConfermaOtp.jsx"));

/** Invoices section */
const FattureArchivio = Loadable(() => import(/* webpackChunkName: "Invoices" */ "./views/amministrazione/fattureArchivio.jsx"));

/** Claims section */
const ReclamiArchivio      = Loadable(() => import("./views/post-vendita/reclami/reclamiArchivio.jsx"));
const ReclamiDettaglio     = Loadable(() => import("./views/post-vendita/reclami/dettaglio/reclamoDettaglio.jsx"));
const ReclamiNuovoReclamo  = Loadable(() => import("./views/post-vendita/reclami/nuovoReclamo.jsx"));
const SostituzioniArchivio = Loadable(() => import("./views/post-vendita/sostituzioni/sostituzioniArchivio.jsx"));
const SostituzioniAttive   = Loadable(() => import("./views/post-vendita/sostituzioni/sostituzioniAttive.jsx"));
const FattureNoteAccredito = Loadable(() => import("./views/post-vendita/noteAccredito.jsx"));
const AutorizzazioniReso   = Loadable(() => import("./views/post-vendita/autorizzazioniReso.jsx"));

/** DDT section */
const DdtArchivio = Loadable(() => import(/* webpackChunkName: "DDT" */ "./views/amministrazione/ddtArchivio.jsx"));

/** Promozioni section */
const PromozioniArchivio = Loadable(() => import(/* webpackChunkName: "Promozioni" */ "./views/promozioni/promozioniArchivio.jsx"));

/** Shipping Forecast section */
const PrevisioneSpedizioniArchivio = Loadable(() => import(/* webpackChunkName: "PrevisioneSpedizioni" */ "./views/ordini/previsioneSpedizioneArchivio.jsx"));

/** Virtual Tour section */
const VirtualTourScorze = Loadable(() => import(/* webpackChunkName: "VirtualTourScorze " */ "./views/virtualtour/virtualtour-scorze.jsx"));
const VirtualTourMilano = Loadable(() => import(/* webpackChunkName: "VirtualTourScorze " */ "./views/virtualtour/virtualtour-milano.jsx"));

/** Schede Montaggio section */
const SchedeMontaggioHome = Loadable(() => import(/* webpackChunkName: "SchedeMontaggioHome " */ "./views/area-tecnica/schedemontaggio.jsx"));
const SchedeMontaggioCategory = Loadable(() => import(/* webpackChunkName: "SchedeMontaggioHome " */ "./views/area-tecnica/schedemontaggiocategory.jsx"));
const SchedaProdotto = Loadable(() => import(/* webpackChunkName: "SchedaProdotto " */ "./views/area-tecnica/schedaProdotto.jsx"));

/** Software section */
const RequistiSoftware = Loadable(() => import(/* webpackChunkName: "RequistiSoftware " */ "./views/software/requisitiSoftware.jsx"));
const MetronInstaller = Loadable(() => import(/* webpackChunkName: "MetronInstaller " */ "./views/software/metronInstaller.jsx"));

/** Arredo3 section */
const Arredo3Recapiti = Loadable(() => import(/* webpackChunkName: "Arredo3Recapiti " */ "./views/arredo3/arredo3Recapiti.jsx"));

/** GtlfViewer section */
const GtlfViewer = Loadable(() => import(/* webpackChunkName: "GtlfViewer" */ "./views/gltfviewer.jsx"));

/** Expo section */
const Expo = Loadable(() => import(/* webpackChunkName: "Expo" */ "./views/ordini/ordiniExpo.jsx"));

/** Tutorial section */
const Tutorial = Loadable(() => import(/* webpackChunkName: "Tutorial" */ "./views/tutorial/tutorial.jsx"));

/** Statistiche section */
const Statistiche = Loadable(() => import(/* webpackChunkName: "Statistiche" */ "./views/statistiche/statistiche.jsx"));

/** Assistenza section */
const Assistenza = Loadable(() => import(/* webpackChunkName: "Assistenza" */ "./views/assistenza.jsx"));

/** Area Download 2.0 */
const AreaDownload = Loadable(() => import(/* webpackChunkName: "View34" */ "./views/area-download/areaDownload.jsx"));

/** Academy */
const Academy = Loadable(() => import(/* webpackChunkName: "View33" */ "./views/academy/academy.jsx"));

const views = {
    Dashboard,
    ClientiArchivio,
    ClientiDettaglio,
    SostituzioniArchivio,
    SostituzioniAttive,
    OrdiniArchivio,
    OrdiniAttivi,
    OrdiniCampioni,
    OrdiniNoteAssistenza,
    OrdiniDettaglio,
    ConfermaOtpRichiestaConfermaOrdine,
    ConfermaOtpAap,
    ReclamiArchivio,
    ReclamiNuovoReclamo,
    ReclamiDettaglio,
    FattureArchivio,
    FattureNoteAccredito,
    PrevisioneSpedizioniArchivio,
    VirtualTourScorze,
    VirtualTourMilano,
    SchedeMontaggioHome,
    SchedeMontaggioCategory,
    SchedaProdotto,
    DdtArchivio,
    PromozioniArchivio,
    RequistiSoftware,
    MetronInstaller,
    Arredo3Recapiti,
    GtlfViewer,
    Expo,
    AutorizzazioniReso,
    Tutorial,
    Statistiche,
    Assistenza,
    AreaDownload,
    Academy
}

export default views;
