import {runInAction, toJS} from "mobx";
import langstore from "../../system/i18n/translator";

const internalUserSearchForCustomer = async function (searchPattern) {
  // API call
  let response = await this.dataLayer({
    url: this.config.paths.apiURL + 'internal/search',
    cacheAge: 1,
    params: {
      search: searchPattern
    },
    userToken: this.userToken
  });

  if (response) {
    return response.data;
  }
};

const generateSSOOnBehalfOf = async function (c_utente) {
  let response = await this.dataLayer({
    url: this.config.paths.apiURL + 'internal/obosso',
    cacheAge: 0,
    params: {
      c_utente: c_utente
    },
    userToken: this.userToken
  });

  if (response.data) {
    await this.trySsoLogin(response.data);
  }
};

const getGruppiPortale = async function (params) {
  let _params = {};

  let response = await this.dataLayer({
    url: this.config.paths.apiURL + 'internal/gruppi',
    cacheAge: 1,
    params: _params,
    userToken: this.userToken
  });

  if (response) {
    runInAction(() => {
      this.gruppi_portale = response.data;
    })
  }
}

export {
  internalUserSearchForCustomer,
  generateSSOOnBehalfOf,
  getGruppiPortale
};