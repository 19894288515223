import React from 'react'
import { inject, observer } from 'mobx-react'

export default inject("rootstore")(observer((props) => {
	return (
		<div className="ts_notifications">
			{props.rootstore.notifications.map((notification, i) => {
				return (
					<div
						key={notification.id + i}
						className={"ts_notification_item " + notification.type}>{notification.message}</div>
				)
			})}
		</div>
	)
}));