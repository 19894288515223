import React from 'react';
import ReactDOM from 'react-dom';
import ErrorView from '../views/errorview.jsx';
import {errorLog} from '../actions/common.js';
import {inject, observer} from 'mobx-react'


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });

    // You can also log the error to an error reporting service
    console.error(error, info);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorView />
    }
    else{
      return this.props.children;
    }
  }
}

ErrorBoundary = inject("rootstore","langstore")(observer(ErrorBoundary));

export {ErrorBoundary}